import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../components/layout';
import Login from '../pages/login';
import Experts from '../pages/experts';
import Expert from '../pages/expert';
import Support from '../pages/support';
import PasswordReset from '../pages/password-reset';
import Match from '../pages/match';
import GiftCardPurchase from '../pages/gift-cards/purchase';
import GiftCardConfirmation from '../pages/gift-cards/confirmation';
import Invite from '../pages/invite';
import Search from '../pages/search';
import PrivateRoutes from './PrivateRoutes';
import { ACCOUNT_TYPES } from '../constants';

function AllRoutes() {
  return (
    <Routes>
      <Route path="/join/:code" element={<Login accountType={ACCOUNT_TYPES.PARENT} />} />
      <Route path="/login/experts" element={<Login accountType={ACCOUNT_TYPES.EXPERT} />} />
      <Route path="/login/parents" element={<Login accountType={ACCOUNT_TYPES.PARENT} />} />
      <Route path="/password_reset" element={<PasswordReset />} />
      <Route path="/match" element={<Match />} />
      <Route path="/gift-cards/:price" element={<GiftCardPurchase />} />
      <Route path="/gift-cards/confirmation" element={<GiftCardConfirmation />} />
      <Route path="/invite" element={<Layout />}>
        <Route path=":code" element={<Invite />} />
      </Route>
      <Route path="/experts/:display_id/:product_id" element={<Expert />} />
      <Route path="/r/:display_id/:product_id" element={<Expert />} />
      <Route path="/experts/:display_id" element={<Expert />} />
      <Route path="/r/:display_id" element={<Expert />} />
      <Route path="/experts" element={<Layout />}>
        <Route path="" element={<Experts />} />
        <Route path=":display_id/:product_id" element={<Experts />} />
      </Route>
      <Route path="/search" element={<Layout />}>
        <Route path="" element={<Search />} />
        <Route path=":display_id/:product_id" element={<Experts />} />
      </Route>
      <Route path="/support" element={<Layout />}>
        <Route path="" element={<Support />} />
      </Route>
      <Route path="*" element={<PrivateRoutes />} />
    </Routes>
  );
}

export default AllRoutes;
