import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Avatar, Box, Button, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';

import { get, patch } from '../../services/api.services';
import { API, CANCELATION_POLICIES, PRODUCT_TYPES } from '../../constants';
import CheckoutForm from './checkout-form';
import {
    SelectedPaymentContainer,
    PaymentContainer,
    DetailText,
} from './payment.style';
import {
    ProductLabel,
    ProductValue,
    ProductTitle,
} from '../product/product.style';
import { capitalizeFirstLetter } from '../../utils/stringHelpers';
import {
    getPriceAfterDiscounts,
    getQuestionPriceAfterDiscounts,
} from '../../utils/coupons';
import config from '../../config';
import { SET_USER } from '../../store/actions';
import { fetchPaymentMethods } from '../../clients/profile';
import ProductDetailLine from '../product/product-detail-line';

const stripePromise = loadStripe(
    config.currentEnv === 'production' ?
    'pk_live_51LPUdOHf16Ud2OOxIA2TxiTEluSTEgonownCZgfuFp1TPeQVRWTL9nyRyhwTUukLBFxOkDKCZ1fCTxXegZqPVB6g00UWCQeR8N' :
    'pk_test_51LPUdOHf16Ud2OOxGrqOrYIX4jQX7gOjT3Xop4J8jSQPTG2gb1qqXdCMPm7T3gpZ9zPub7s28XYnjFgAIjOBox2200IoGhQhWk'
);

function PaymentMethod({ product, price, confirmText, onSuccess, cancel }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [addNewMethod, setAddNewMethod] = useState(false);
    const [applyGiftCard, setApplyGiftCard] = useState(false);
    const [giftCardNumber, setGiftCardNumber] = useState();
    const [clientSecret, setClientSecret] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(product.default_payment_plan);
    const priceAfterDiscounts = product ? getPriceAfterDiscounts(product, user.profile.discount?.coupon, user.profile.feature_flags.tracks) : getQuestionPriceAfterDiscounts(price, user.profile.discount?.coupon);
    const walletBalanceExceedsPrice = (user.profile.available_balance / -100) >= priceAfterDiscounts;

    const [useWallet, setUseWallet] = useState(walletBalanceExceedsPrice);


    const getPaymentMethods = async () => {
        const cards = await fetchPaymentMethods(navigate, user, token);
        setPaymentMethods(cards);

        if (cards.length) {
            setSelectedPaymentMethod(cards[0].id);
        }
    }

    const redeemGiftCard = async () => {
        const result = await patch(
            `${API.GIFT_CARD}${giftCardNumber}/redeem/`,
            {},
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate(`/login/${user.profile.profile_type}s`);
        }
        else if (result.status === 200) {
            const result = await get(
                `${API.USER}${user.id}`,
                token,
            );
        
            if (result.status === 200) {
                dispatcher({
                type: SET_USER,
                payload: {
                    user: result.data,
                },
                });
            }

            setApplyGiftCard(false);
        }
        else {
          // TODO: Error states
        }
    }

    let disableSubmit = false;
    if (priceAfterDiscounts > 0 && !selectedPaymentMethod && (!useWallet || !walletBalanceExceedsPrice)) {
        disableSubmit = true;
    }

    useEffect(() => {
        getPaymentMethods();
    }, []);

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        appearance,
    };

    return (
        <Grid container>
            {product && (
                <Grid item xs={12} md={priceAfterDiscounts > 0 ? 6 : 12}>
                    <Box pl={4} pr={4}>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                            <Avatar
                                src={product.image_url}
                                sx={{ width: 75, height: 50, borderRadius: 4 }}
                                variant="square"
                            />
                            <Box sx={{ ml: 2 }}>
                                <ProductTitle>{product.title}</ProductTitle>
                            </Box>
                        </Box>
                        {product.price_description && (
                            <ProductDetailLine
                                label="Price"
                                value={product.price_description}
                            />
                        )}
                        {/*<ProductDetailLine
                            label="Price"
                            value={
                                priceAfterDiscounts !== product.price ? (
                                    <>
                                        <span style={{ opacity: '0.7', textDecoration: 'line-through' }}>
                                            ${product.price.toLocaleString()}
                                        </span> {priceAfterDiscounts === 0 ? 'Free' : `$${priceAfterDiscounts.toLocaleString()}`}
                                    </>
                                ) : (
                                    <span>${product.price.toLocaleString()}</span>
                                )
                            }
                            firstLine
                        />*/}
                        {/*product.additional_payment_plans_offered.length === 0 && priceAfterDiscounts > 0 && (
                            <ProductDetailLine
                                label="Payment Terms"
                                value={product.default_payment_plan.description}
                            />
                        )*/}
                        {/*product.product_type !== PRODUCT_TYPES.RESOURCE && priceAfterDiscounts > 0 && (
                            <ProductDetailLine
                                label="Cancellation Policy"
                                value={capitalizeFirstLetter(product.cancelation_policy)}
                                description={CANCELATION_POLICIES.filter((policy) => policy.value === product.cancelation_policy)[0].description}
                            />
                        )*/}
                        {/*product.product_type === PRODUCT_TYPES.SINGLE || product.product_type === PRODUCT_TYPES.GROUP && (
                            <ProductDetailLine
                                label="Duration"
                                value={product.duration ? `${product.duration} min${product.duration > 1 ? 's' : ''}` : ''}
                            />
                        )*/}
                        {/*product.product_type !== PRODUCT_TYPES.RESOURCE && (product.virtual || product.in_person) && (
                            <ProductDetailLine
                                label="Location"
                                value={`${product.virtual ? 'Virtual' : ''}${product.in_person ? `${product.virtual ? ', In Person' : 'In Person'}` : ''}`}
                            />
                        )*/}
                    </Box>
                </Grid>
            )}
            <Grid item xs={12} md={product && priceAfterDiscounts ? 6 : 12}>
                <Box sx={{ width: 300, textAlign: 'center', margin: '0 auto' }}>
                    {priceAfterDiscounts > 0 && (
                        <Stack spacing={2}>
                            {product.additional_payment_plans_offered.length > 0 && (
                                <Box textAlign="left">
                                    <Box mb={1}>
                                        <Typography variant="subtitle2">
                                            {product.additional_payment_plans_offered.length > 0  ? 'Payment Options' : 'Payment Terms'}
                                        </Typography>
                                    </Box>
                                    <Select
                                        id="selected-payment-plan"
                                        value={selectedPaymentPlan}
                                        onChange={(e) => setSelectedPaymentPlan(e.target.value)}
                                        sx={{ width: '100%', color: 'black' }}
                                        disabled={product.additional_payment_plans_offered.length > 0 ? false : true}
                                    >
                                        <MenuItem value={product.default_payment_plan}>{product.default_payment_plan.description}</MenuItem>
                                        {product.additional_payment_plans_offered.map((plan) => (
                                            <MenuItem value={plan}>{plan.description}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            )}
                            <Box textAlign="left" sx={{ mt: { xs: 2, md: 0 }}}>
                                <Typography variant="subtitle2">
                                    Payment Method
                                </Typography>
                            </Box>
                            {user.profile?.available_balance < 0 && (
                                <Box key="wallet">
                                    {useWallet ? (
                                        <SelectedPaymentContainer onClick={() => setUseWallet(false)}>
                                            Available Wallet Balance: ${(user.profile.available_balance / -100).toLocaleString()}
                                        </SelectedPaymentContainer>
                                    ) : (
                                        <PaymentContainer onClick={() => setUseWallet(true)}>
                                            Available Wallet Balance: ${(user.profile.available_balance / -100).toLocaleString()}
                                        </PaymentContainer>
                                    )}
                                </Box>
                            )}
                            {!walletBalanceExceedsPrice && (
                                <>
                                    {paymentMethods.map((paymentMethod) => (
                                        <Box key={paymentMethod.id}>
                                            {selectedPaymentMethod === paymentMethod.id ? (
                                                <SelectedPaymentContainer onClick={() => setSelectedPaymentMethod(paymentMethod.id)}>
                                                    {capitalizeFirstLetter(paymentMethod.card.brand) || 'Card'} ending in &bull;&bull;&bull;&bull; {paymentMethod.card.last4}
                                                </SelectedPaymentContainer>
                                            ) : (
                                                <PaymentContainer onClick={() => setSelectedPaymentMethod(paymentMethod.id)}>
                                                    {capitalizeFirstLetter(paymentMethod.card.brand) || 'Card'} ending in &bull;&bull;&bull;&bull; {paymentMethod.card.last4}
                                                </PaymentContainer>
                                            )}
                                        </Box>
                                    ))}
                                    {paymentMethods.length > 0 && !addNewMethod && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setAddNewMethod(true)}
                                        >
                                            + Add Payment Method
                                        </Button>
                                    )}
                                    {(paymentMethods.length === 0 || addNewMethod) && (
                                        <Elements
                                            stripe={stripePromise}
                                            options={options}
                                        >   
                                            <CheckoutForm
                                                onSuccess={() => {
                                                    setAddNewMethod(false);
                                                    getPaymentMethods();
                                                }}
                                            />
                                        </Elements>
                                    )}
                                </>
                            )}
                            <>
                                {!applyGiftCard && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setApplyGiftCard(true)}
                                    >
                                        Apply a Gift Card
                                    </Button>
                                )}
                                {applyGiftCard && (
                                    <>
                                        <TextField
                                            label="Gift card number"
                                            id="gift-card-number"
                                            variant="outlined"
                                            value={giftCardNumber}
                                            onChange={(e) => setGiftCardNumber(e.target.value)}
                                            sx={{ width: '100%' }}
                                        />
                                        <Button variant="contained" color="secondaryLight" onClick={redeemGiftCard}>Apply</Button>
                                    </>
                                )}
                            </>
                        </Stack>
                    )}
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Stack spacing={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={cancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={disableSubmit}
                                    onClick={() => onSuccess(selectedPaymentMethod || null, useWallet, selectedPaymentPlan)}
                                >
                                    {confirmText}
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

PaymentMethod.propTypes = {
    price: PropTypes.number,
    confirmText: PropTypes.string,
    onSuccess: PropTypes.func,
};

export default PaymentMethod;
