import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Fab,
  Grid,
  Modal,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import EmailIcon from '@mui/icons-material/Email';

import { useAuth } from '../../hooks';
import { displayName } from '../../utils/profile';
import { State } from '../../types';
import { fetchDisplayProfile } from '../../clients/profile';
import { fetchProductsBySeller, fetchProduct } from '../../clients/product';
import { stringToColor, isLightColor } from '../../utils/stringHelpers';
import ProductCard from '../../components/product/product-card';
import { PRODUCT_TYPES } from '../../constants';
import Badge from '../../components/badges/badge';
import Logo from '../../assets/images/parentswarm-logo.svg';
import ProductPurchase from '../../components/product/purchase';
import { OPEN_PRODUCT, CLOSE_PRODUCT } from '../../store/actions';
import Section from './section';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import ContactMeModal from '../../components/common/modal/contact-me.jsx';

const DEFAULT_BACKGROUND = 'white';

interface ExpertProps {}

export default function Expert({}: ExpertProps) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const profileId = params.display_id;
  const checkoutProductId = params.product_id;

  const { user, token } = useAuth();
  const profileMap = useSelector(
    (state: State) => state.profiles.profilesByDisplayId,
  );
  const productIdsBySellerId = useSelector(
    (state: State) => state.products.productIdsBySellerId,
  );
  const productsById = useSelector(
    (state: State) => state.products.productsById,
  );
  const openProduct = useSelector((state: State) => state.products.openProduct);

  const cameFromSearch = queryParams.get('search');

  const [activeTab, setActiveTab] = useState(checkoutProductId ? 1 : 0);
  const [checkoutLoaded, setCheckoutLoaded] = useState(false);

  const profile = profileMap[profileId];
  const seoTitle = profile
    ? `${
        profile.business_name ||
        displayName(profile.first_name, profile.last_name)
      } | Parentswarm`
    : 'Parentswarm';
  const products =
    profile && productIdsBySellerId[profile.id]
      ? productIdsBySellerId[profile.id].filter(
          (productId) =>
            !productsById[productId].archived &&
            (productsById[productId].product_type !== PRODUCT_TYPES.GROUP ||
              (productsById[productId].product_type === PRODUCT_TYPES.GROUP &&
                moment(productsById[productId].event_date) >= moment())),
        )
      : [];

  let textColor = '#FFFFFF';

  if (profile?.page_settings?.cover_color) {
    textColor = isLightColor(profile.page_settings?.cover_color)
      ? 'info.main'
      : '#FFFFFF';
  }

  const fetchProfile = async () => {
    fetchDisplayProfile(dispatcher, profileId, token);
  };

  const fetchProducts = async () => {
    fetchProductsBySeller(dispatcher, navigate, token, profile.id);

    if (checkoutProductId) {
      fetchProduct(dispatcher, navigate, token, checkoutProductId);
    }
  };

  const displayProduct = (product, bookNow) => {
    dispatcher({
      type: OPEN_PRODUCT,
      payload: {
        product,
        bookNow,
      },
    });
  };

  const closeProduct = () => {
    dispatcher({
      type: CLOSE_PRODUCT,
      payload: {},
    });
  };

  useEffect(() => {
    if (!profileMap[profileId]) {
      fetchProfile();
    }
  }, [profileId]);

  useEffect(() => {
    if (profile) {
      fetchProducts();
    }
  }, [profile]);

  useEffect(() => {
    if (
      !checkoutLoaded &&
      checkoutProductId &&
      productsById[checkoutProductId] &&
      !productsById[checkoutProductId].archived
    ) {
      setCheckoutLoaded(true);
      displayProduct(productsById[checkoutProductId], false);
    }
  }, [productIdsBySellerId]);

  return (
    <div>
      {profile && (
        <Box
          sx={{
            backgroundColor: DEFAULT_BACKGROUND,
          }}
        >
          <Helmet>
            <title>{seoTitle}</title>
            <meta name="title" content={seoTitle} />
            <meta name="description" content={profile.bio} />
            <meta property="twitter:title" content={seoTitle} />
            <meta property="twitter:description" content={profile.bio} />
          </Helmet>
          {user && <Header sidebarToggle={true} />}
          <Box
            sx={{
              backgroundColor: 'primary.main',
            }}
          >
            <Container maxWidth="md">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height={325}
              >
                <Avatar
                  alt={profile.first_name}
                  src={profile.profile_image_url}
                  sx={{
                    width: 175,
                    height: 175,
                    bgcolor: profile.profile_image_url
                      ? '#FFFFFF'
                      : stringToColor(
                          profile.business_name || profile.first_name,
                        ),
                  }}
                >
                  {!profile.profile_image_url
                    ? profile.business_name?.charAt(0) ||
                      profile.first_name?.charAt(0)
                    : null}
                </Avatar>
                <Box mt={2} textAlign="center">
                  <Typography variant="h5" color={textColor}>
                    {profile.business_name ||
                      displayName(profile.first_name, profile.last_name)}
                    {profile.pronouns && (
                      <Typography variant="caption">
                        ({profile.pronouns})
                      </Typography>
                    )}
                  </Typography>
                  <Typography color={textColor}>
                    {profile.one_liner || profile.roles?.split(',').join(', ')}
                  </Typography>
                </Box>
                {profile.locations_served &&
                  profile.locations_served.length > 0 && (
                    <Stack
                      mt={2}
                      spacing={1}
                      direction="row"
                      textAlign="center"
                    >
                      {profile.locations_served.map((l) => (
                        <Chip color="secondary" label={l.name} />
                      ))}
                    </Stack>
                  )}
              </Box>
            </Container>
          </Box>
          {/*<Box sx={{ backgroundColor: '#FFFFFF' }}>
                        <Box sx={{ borderTop: 1, borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={activeTab}
                                onChange={(e, newValue) => setActiveTab(newValue)} aria-label="basic tabs example"
                                centered
                            >
                                <Tab
                                    id="tab-0"
                                    label="Home"
                                    aria-controls="tabpanel-0"
                                />
                                {products.length > 0 && (
                                    <Tab
                                        id="tab-1"
                                        label="My Services"
                                        aria-controls="tabpanel-1"
                                    />
                                )}
                            </Tabs>
                        </Box>
                    </Box>*/}
            <Box mt={4} pb={12} sx={{ backgroundColor: DEFAULT_BACKGROUND }}>
                <Container maxWidth="md">
                    <Box
                        role="tabpanel"
                        hidden={activeTab !== 0}
                        id="tabpanel-0"
                        aria-labelledby="tab-0"
                        mt={2}
                    >
                        <Grid container spacing={2}>
                            {profile.booking_target && profile.booking_target_type && (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
                                >
                                    {profile.booking_target_type == 'link' && (
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            onClick={() =>
                                                window.open(profile.booking_target, '_blank')
                                            }
                                        >
                                            <Stack direction="row" spacing={1}>
                                                <OpenInNewRoundedIcon />
                                                <span>Learn More</span>
                                            </Stack>
                                        </Button>
                                    )}
                                    {/*profile.booking_target_type == 'email' && (
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            href={`mailto:${profile.booking_target}`}
                                            size="large"
                                        >
                                            <Stack direction="row" spacing={1}>
                                                <EmailRoundedIcon />
                                                <span>
                                                    Contact{' '}
                                                    {profile.business_name ||
                                                        displayName(
                                                        profile.first_name,
                                                        profile.last_name,
                                                    )}
                                                </span>
                                            </Stack>
                                        </Button>
                                    )*/}
                                    {profile.booking_target_type == 'phone' && (
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            href={`tel:${profile.booking_target}`}
                                        >
                                            <Stack direction="row" spacing={1}>
                                                <LocalPhoneRoundedIcon />
                                                <span>
                                                Call{' '}
                                                {profile.business_name ||
                                                    displayName(
                                                    profile.first_name,
                                                    profile.last_name,
                                                    )}
                                                </span>
                                            </Stack>
                                        </Button>
                                    )}
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleOpen()}
                                    >
                                        <Stack direction="row" spacing={1}>
                                            <EmailIcon />
                                            <span>Contact Me</span>
                                        </Stack>
                                    </Button>
                                    <ContactMeModal profile={profile} open={open} handleClose={handleClose} />
                                </Grid>
                            )}
                            {profile.bio && (
                                <Section title="About" sm={12} md={12} lg={12}>
                                    <Typography variant="body1">
                                        {profile.bio}
                                    </Typography>
                                </Section>
                            )}
                            {products.length > 0 && (
                                <Section title="Featured Services">
                                    <Box mt={1}>
                                        <Grid container spacing={1}>
                                            {products.map((productId, index) => (
                                                <>
                                                    {index < 3 && (
                                                        <Grid item xs={12} sm={4} key={productId}>
                                                            <ProductCard
                                                                product={productsById[productId]}
                                                                key={productId}
                                                                secondaryCtaAction={() => displayProduct(productsById[productId], false)}
                                                                primaryCtaAction={() => displayProduct(productsById[productId], true)}
                                                                miniProfile={false}
                                                            />
                                                        </Grid>
                                                    )}
                                                </>
                                            ))}
                                        </Grid>
                                        {/*<Box mt={2} display="flex" justifyContent="center">
                                            <Button
                                                variant="text"
                                                color="info"
                                                onClick={() => {
                                                    window.scrollTo(0, 0);
                                                    setActiveTab(1)
                                                }}
                                                style={{ textDecoration: 'underline' }}
                                            >
                                                View All Services
                                            </Button>
                                        </Box>*/}
                                    </Box>
                                </Section>
                            )}
                            <Section title="Details" sm={12} md={6} lg={6}>
                                <Stack spacing={2}>
                                    <Typography variant="caption">
                                        <b>Business Type:</b>
                                    </Typography>
                                    <Box>
                                        {profile.business_type === "coach" && <Chip label="Independent birth worker or parent coach" />}
                                        {profile.business_type === "creator" && <Chip label="Creator / influencer" />}
                                        {profile.business_type === "company" && <Chip label="For-profit company" />}
                                        {profile.business_type === "nonprofit" && <Chip label="Nonprofit organization" />}
                                        {profile.business_type === "other" && <Chip label={profile.business_type_other} />}
                                    </Box>
                                    <Typography variant="caption">
                                        <b>Offers:</b>
                                    </Typography>
                                    <Box>
                                        {profile.resource_category === "products" && <Chip label="Physical goods / products" />}
                                        {profile.resource_category === "tech" && <Chip label="Tech solution" />}
                                        {profile.resource_category === "services" && <Chip label="Services" />}
                                        {profile.resource_category === "other" && <Chip label={profile.resource_category_other} />}
                                    </Box>
                                    {profile.reimbursements_accepted && profile.reimbursements_accepted.length > 0 && (
                                        <>
                                            <Typography variant="caption">
                                                <b>Accepts:</b>
                                            </Typography>
                                            <Stack direction="row" spacing={1}>
                                                {profile.reimbursements_accepted.map((reimbursment) => (
                                                    <>
                                                        {reimbursment === "fsa" && <Chip label="FSA" />}
                                                        {reimbursment === "hra" && <Chip label="HRA" />}
                                                        {reimbursment === "hsa" && <Chip label="HSA" />}
                                                        {reimbursment === "medicaid" && <Chip label="Medicaid" />}
                                                        {reimbursment === "medicare" && <Chip label="Medicare" />}
                                                        {reimbursment === "private_ins" && <Chip label="Private Insurance" />}
                                                    </>
                                                ))}
                                            </Stack>
                                        </>
                                    )}
                                    <Typography variant="caption">
                                        <b>Pricing:</b>
                                    </Typography>
                                    <Box>
                                        {profile.pricing === "free" && <Chip label="Free" />}
                                        {profile.pricing === "budget" && <Chip label="Budget" />}
                                        {profile.pricing === "mid" && <Chip label="Mid-Market" />}
                                        {profile.pricing === "premium" && <Chip label="Premium" />}
                                    </Box>
                                </Stack>
                            </Section>
                            {profile.badges && profile.badges.length > 0 && (
                                <Section sm={12} md={6} lg={6} title="Badges Earned">
                                    <Box display="flex" flexWrap="wrap">
                                        {profile.badges.map((badge) => (
                                            <Badge badgeKey={badge} size="normal" key={badge} />
                                        ))}
                                    </Box>
                                </Section>
                            )}
                            {profile.special_offer && (
                                <Section title="Special Offers" sm={12} md={6} lg={6}>
                                <Stack mt={1} spacing={2}>
                                    <Box>
                                    <Typography variant="body2">
                                        {profile.special_offer}
                                    </Typography>
                                    </Box>
                                </Stack>
                                </Section>
                            )}
                            {profile.testimonials?.length > 0 && (
                                <Section title="Testimonials" sm={12} md={6} lg={6}>
                                <Stack mt={1} spacing={2}>
                                    {profile.testimonials.map((testimonial) => (
                                    <Box>
                                        <Typography variant="body1">
                                        {testimonial.body}
                                        </Typography>
                                        <Typography variant="body2">
                                        <b>- {testimonial.name}</b>
                                        </Typography>
                                    </Box>
                                    ))}
                                </Stack>
                                </Section>
                            )}
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </Box>
      )}

        <Box
            sx={{ backgroundColor: '#ffffff', borderTop: '1px solid #e8e8e8' }}
            p={1}
            textAlign="center"
        >
            <Button href="https://parentswarm.com" variant="text">
                <Box mb={-3}>
                <Box>
                    <Typography variant="caption">
                    <b>Powered By</b>
                    </Typography>
                </Box>
                <img src={Logo} width={150} />
                </Box>
            </Button>
        </Box>
        <Modal
            open={productsById[openProduct]}
            onClose={closeProduct}
        >
            <ProductPurchase
                product={productsById[openProduct]}
                onClose={closeProduct}
            />
        </Modal>
    </div>
  );
}
