import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment-timezone';
import { Box, Button, Grid, IconButton, TextField, Typography, Stack } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import { API, PRODUCT_TYPES } from '../../constants';
import { post } from '../../services/api.services';
import DismissIcon from '../../assets/icons/Dismiss.svg';
import Security from '../../assets/images/Security.png';
import Confetti from '../../assets/images/confetti.gif';
import {
    Range,
    ScheduleTitle,
} from './product.style';
import {
    ModalContentContainer,
    ModalHeader,
    ModalHeaderLeft,
    ModalTitle,
    ModalBody,
} from '../profile/Profile.style';
import PaymentMethod from '../payment/payment-method';
import ProductDisplay from './product-display';
import ProductShare from './product-share';
import ParentRegistration from '../register/parent-registration';
import {
    SuccessModalTitle,
    SuccessModalText,
} from '../header/Header.style';
import { EmailField } from '../../pages/login/Login.style';
import ContactMeModal from '../common/modal/contact-me';

export default function Purchase({
    product,
    skipPreview = false,
    onClose,
    preview,
    onEdit,
    purchased = false,
}) {
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [step, setStep] = useState(1);
    const [dateRanges, setDateRanges] = useState({});
    const [dateSelected, setDateSelected] = useState(null);
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [contactError, setContactError] = useState(false);
    const [openContactModal, setOpenContactModal] = useState(false);

    let friendlyProductType = '1-on-1 Support / Coaching';
    let purchaseLanguage = 'Book';

    switch (product.product_type) {
        case PRODUCT_TYPES.GROUP:
            friendlyProductType = 'Live Group Learning';
            break;
        case PRODUCT_TYPES.SUPPORT:
            friendlyProductType = 'Support Group';
            break;
        case PRODUCT_TYPES.RESOURCE:
            friendlyProductType = 'Resource';
            purchaseLanguage = 'Buy';
            break;
        case PRODUCT_TYPES.TECH:
            friendlyProductType = 'Tech Solution';
            purchaseLanguage = 'Buy';
            break;
        case PRODUCT_TYPES.PRODUCT:
            friendlyProductType = 'Physical Product';
            purchaseLanguage = 'Buy';
            break;
        case PRODUCT_TYPES.PACKAGE:
            friendlyProductType = 'Package';
            break;
        default:
            friendlyProductType = '1-on-1 Session';
            break;
    }

    const titleMap = {
        1: `${friendlyProductType} Details`,
        2: `${purchaseLanguage} ${friendlyProductType}`,
        3: `${purchaseLanguage} ${friendlyProductType}`,
        4: `${purchaseLanguage} ${friendlyProductType}`,
    };

    const ranges = [
        {
            start: 6,
            end: 8,
            display: '6:00 am - 8:00 am',
        },
        {
            start: 8,
            end: 10,
            display: '8:00 am - 10:00 am',
        },
        {
            start: 10,
            end: 12,
            display: '10:00 am - 12:00 pm',
        },
        {
            start: 12,
            end: 14,
            display: '12:00 pm - 2:00 pm',
        },
        {
            start: 14,
            end: 16,
            display: '2:00 pm - 4:00 pm',
        },
        {
            start: 16,
            end: 18,
            display: '4:00 pm - 6:00 pm',
        },
        {
            start: 18,
            end: 20,
            display: '6:00 pm - 8:00 pm',
        },
    ];

    const timezone = moment.tz.guess();

    const addRange = (index) => {
        setDateRanges((prev) => {
            const dateRangeCopy = { ...prev };
            dateRangeCopy[dateSelected].push(index);
            return dateRangeCopy;
        });
    };

    const removeRange = (index) => {
        setDateRanges((prev) => {
            let dateRangeCopy = { ...prev };
            dateRangeCopy[dateSelected].splice(dateRangeCopy[dateSelected].indexOf(index), 1);
            return dateRangeCopy;
        });
    };

    const formatDateRanges = () => {
        const dateRangesFormatted = {
            timezone,
            days: {},
        };

        for (const [key, value] of Object.entries(dateRanges)) {
            dateRangesFormatted.days[key] = [];

            for (let i = 0; i < value.length; i++) {
                dateRangesFormatted.days[key].push(ranges[value[i]]);
            }
        }

        return dateRangesFormatted;
    };

    const purchase = async (paymentMethod, useWallet, paymentPlan) => {
        const result = await post(
          `${API.ATTENDEE}`,
          {
            product_id: product.id,
            date_ranges: formatDateRanges(),
            payment_method: paymentMethod,
            use_wallet: useWallet,
            payment_plan_id: paymentPlan.id,
          },
          token,
        );

        if (result.status === 403 || result.status === 401) {
          navigate('/login/parents');
        }
        else if (result.status === 201) {
            setStep(5);
        }
        else {
            setStep(product.product_type === PRODUCT_TYPES.SINGLE ? 3 : 2);   
        }
    };

    const contact = async () => {
        setContactError(false);
    
        const result = await post(
            `${API.PROFILE}${product.seller.id}/contact/`,
            {
                contact_name: contactName,
                contact_email: contactEmail,
                contact_phone: contactPhone,
                contact_message: contactMessage,
                product_title: product.title,
            },
            token,
        );

        if (result.status == 200) {
            setStep(7);
        } else {
            setContactError(true);
        }
    };

    const checkout = () => {
        if (product.external_registration_link) {
            window.open(product.external_registration_link, '_blank');
        } else if (product.product_type === PRODUCT_TYPES.SINGLE) {
            setStep(2);
        } else {
            setStep(3);
        }
    };

    const onPurchase = () => {
        if (product.external_registration_link) {
            window.open(product.external_registration_link, '_blank');
        }

        if (!product.booking_target_type || product.booking_target_type === 'email') {
            setOpenContactModal(true);
        } else if (product.booking_target_type === 'link') {
            window.open(product.booking_target, '_blank');
        }
    }

    useEffect(() => {
        if (step === 3 && user) {
            setStep(4);
        }
    });

    useEffect(() => {
        if (skipPreview) {
            if (product.product_type === PRODUCT_TYPES.SINGLE) {
                setStep(2);
            } else {
                setStep(3);
            }
        }
    }, [skipPreview]);

    const hasPurchased = purchased || (product.attendees.filter((attendees) => (user && attendees.profile === user.profile.id)).length > 0);

    return (
        <ModalContentContainer>
            <ModalHeader>
                <ModalHeaderLeft>
                    <IconButton onClick={onClose}>
                        <img src={DismissIcon} alt="share" width={12} height={12} />
                    </IconButton>
                    <ModalTitle>
                        {titleMap[step]}
                    </ModalTitle>
                </ModalHeaderLeft>
                {step === 1 && (
                    <ProductShare product={product} />
                )}
            </ModalHeader>
            <ModalBody>
                {step === 1 && (
                    <>
                        <ProductDisplay product={product} hasPurchased={hasPurchased} />

                        <ContactMeModal profile={product.seller} open={openContactModal} handleClose={() => setOpenContactModal(false)} />

                        <Box sx={{ p: 2, textAlign: 'center' }}>
                            <Button variant="contained" onClick={onPurchase}>
                                {purchaseLanguage} Now
                            </Button>
                        </Box>

                        {/*!preview && (product.product_type !== PRODUCT_TYPES.GROUP || (moment(product.event_date) >= moment() && !hasPurchased)) && (
                            <Box sx={{ p: 2, textAlign: 'center' }}>
                                <Button variant="contained" onClick={checkout}>
                                    {purchaseLanguage} Now
                                </Button>
                            </Box>
                        )*/}
                        {/*preview && onEdit && (
                            <Box sx={{ p: 2, textAlign: 'center' }}>
                                <Button variant="contained" onClick={onEdit}>Edit</Button>
                            </Box>
                        )*/}
                    </>
                )}
                {step === 2 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center' }}>
                                <ScheduleTitle>Pick unlimited time ranges and dates that work for you</ScheduleTitle>
                                <Box mt={1} sx={{ fontSize: '12px', fontFamily: 'Open Sans' }}>
                                    We kindly suggest that you select times on a few days to ensure expert availability
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <StaticDatePicker
                                        displayStaticWrapperAs="desktop"
                                        openTo="day"
                                        views={['day']}
                                        minDate={moment.now()}
                                        disableHighlightToday
                                        onChange={(selection) => {
                                            const dateStr = selection.format('YYYY-MM-DD');

                                            if (!dateRanges[dateStr]) {
                                                setDateRanges((prev) => {
                                                    const dateRangesCopy = { ...prev };
                                                    dateRangesCopy[dateStr] = [];
                                                    return dateRangesCopy;
                                                });
                                            }
                                            setDateSelected(dateStr);
                                        }}
                                        showDaysOutsideCurrentMonth
                                        renderInput={(params) => {
                                            return <TextField {...params} />;
                                        }}
                                        renderDay={(day, _value, DayComponentProps) => {
                                            const dateStr = day.format('YYYY-MM-DD');

                                            if (dateStr === dateSelected) {
                                                return (
                                                    <PickersDay {...DayComponentProps} style={{ background: '#5371ff', color: '#FFFFFF' }}/>
                                                );
                                            } else if (dateRanges[dateStr] && dateRanges[dateStr].length) {
                                                return (
                                                    <Box sx={{ borderBottom: '2px solid #5371ff' }}>
                                                        <PickersDay {...DayComponentProps} />
                                                    </Box>
                                                ); 
                                            } else {
                                                return (
                                                    <PickersDay {...DayComponentProps} />
                                                );
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                                <Box sx={{ display: { xs: 'none', md: 'block' }, mt: 1 }}>
                                    <Button shade="secondaryLight" onClick={onClose}>Cancel</Button>
                                </Box>
                            </Box>
                        </Grid>
                        {dateSelected && (
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ mb: 1 }}>
                                        <ScheduleTitle style={{ fontSize: '14px' }}>
                                            Time ranges for {moment(dateSelected).format('MMMM Do')} (Time in {moment.tz.zone(timezone).abbr(moment(dateSelected).format('x'))})
                                        </ScheduleTitle>
                                    </Box>
                                    {ranges.map((range, index) => (
                                        <Range
                                            key={range.display}
                                            onClick={() => {
                                                if (dateRanges[dateSelected].includes(index)) {
                                                    removeRange(index);
                                                } else {
                                                    addRange(index);
                                                }
                                            }}
                                            style={dateRanges[dateSelected].includes(index) ? { background: '#FFE7E7', borderColor: '#5371ff' } : {}}
                                        >
                                            {range.display}
                                        </Range>
                                    ))}
                                    <Box sx={{ mt: 1 }}>
                                        <Button variant="contained" color="primary" onClick={() => user ? setStep(4) : setStep(3)}>Next</Button>
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                )}
                {step === 3 && (
                    <ParentRegistration
                        onBack={() => setStep(product.product_type === PRODUCT_TYPES.SINGLE ? 2 : 1)}
                    />
                )}
                {step === 4 && (
                    <PaymentMethod
                        product={product}
                        price={product.price}
                        confirmText="Purchase"
                        onSuccess={purchase}
                        cancel={onClose}
                    />
                )}
                {step === 5 && (
                    <Box>
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            <img src={Security} alt="success" width={100} />
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            {product.product_type === PRODUCT_TYPES.SINGLE && (
                                <SuccessModalTitle>Your booking request has been sent!</SuccessModalTitle>
                            )}
                            {product.product_type !== PRODUCT_TYPES.SINGLE && (
                                <SuccessModalTitle>You're all set!</SuccessModalTitle>
                            )} 
                        </Box>
                        <Box sx={{ mb: 4 }}>
                            <SuccessModalText>
                                {product.product_type === PRODUCT_TYPES.RESOURCE && (
                                    <span>Your resources will be delivered to your email and available in your account.</span>
                                )}
                                {product.product_type === PRODUCT_TYPES.GROUP && (
                                    <span>Check your email or dashboard for confirmation details and a link to join the session.</span>
                                )}
                                {product.product_type === PRODUCT_TYPES.SINGLE && (
                                    <span>Check your email or dashboard for confirmation details.</span>
                                )}
                                {product.product_type === PRODUCT_TYPES.PACKAGE && (
                                    <span>Check your email or dashboard for confirmation details and next steps.</span>
                                )} 
                            </SuccessModalText>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => {
                                    onClose();
                                    navigate('/dashboard')
                                }}
                            >
                                Home
                            </Button>
                        </Box>
                    </Box>
                )}
                {step === 6 && (
                    <Stack spacing={2}>
                        <Typography variant="h5">Contact {product.seller.first_name}</Typography>
                        <Typography variant="caption">
                            {product.seller.first_name} manages their bookings outside of Parentswarm, so your request will be sent via email.{' '}
                            If the expert has not responded within a few days, please let us know so that we can{' '}
                            update their average response time and connect you with a different provider.
                        </Typography>

                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="contact-name"
                                        label="Name"
                                        variant="outlined"
                                        value={contactName}
                                        onChange={(e) => setContactName(e.target.value)}
                                        inputProps={{ maxLength: 64 }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="contact-email"
                                        label="Email"
                                        variant="outlined"
                                        type="email"
                                        value={contactEmail}
                                        onChange={(e) => setContactEmail(e.target.value)}
                                        inputProps={{ maxLength: 128 }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="contact-phone"
                                        label="Phone (optional)"
                                        variant="outlined"
                                        value={contactPhone}
                                        onChange={(e) => setContactPhone(e.target.value)}
                                        inputProps={{ maxLength: 32 }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="contact-message"
                                        label="Message"
                                        variant="outlined"
                                        multiline
                                        minRows={4}
                                        value={contactMessage}
                                        onChange={(e) => setContactMessage(e.target.value)}
                                        inputProps={{ maxLength: 512 }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            {contactError && (
                                <Box mt={2}>
                                    Something went wrong.
                                </Box>
                            )}
                            <Box mt={2} display="flex" flexDirection="row-reverse">
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={contact}
                                    disabled={contactName.length === 0 || contactEmail.length === 0 || contactMessage.length === 0}
                                >
                                    Send
                                </Button>
                            </Box>
                        </Box>
                    </Stack>
                )}
                {step === 7 && (
                    <Box>
                        <Box sx={{ textAlign: 'center', mb: 2, ml: 2 }}>
                            <img src={Confetti} alt="success" width={100} />
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <SuccessModalTitle>Sent!</SuccessModalTitle>
                        </Box>
                        <Box sx={{ mb: 4 }}>
                            <SuccessModalText>
                                Your message has been sent and you will hear from {product.seller.first_name}{' '}
                                via email.
                            </SuccessModalText>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => {
                                    onClose();
                                    navigate('/support')
                                }}
                            >
                                Browse Support
                            </Button>
                        </Box>
                    </Box>
                )}
            </ModalBody>
        </ModalContentContainer>
    );
}
