import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';

import Card from '../common/card';
import OneOnOneImg from '../../assets/images/OneOnOne.png';
import { getPriceAfterDiscounts } from '../../utils/coupons';
import { PRODUCT_TYPES } from '../../constants';
import { saveProduct } from '../../clients/product';

export default function ProductCard({
    product,
    primaryCta,
    primaryCtaAction,
    secondaryCta="View",
    secondaryCtaAction,
    variant="normal",
    miniProfile=true,
}) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const coupon = user?.profile?.discount?.coupon;
    const discountedPrice = getPriceAfterDiscounts(product, coupon, user?.profile?.feature_flags.tracks);
    
    let tag;
    let defaultPrimaryCta = 'Book';
    
    switch (product.product_type) {
        case PRODUCT_TYPES.SINGLE:
            tag = '1-on-1';
            break;
        case PRODUCT_TYPES.GROUP:
            tag = 'Group';
            break;
        case PRODUCT_TYPES.SUPPORT:
            tag = 'Support Group';
            break;
        case PRODUCT_TYPES.RESOURCE:
            tag = 'Resource';
            defaultPrimaryCta = 'Buy';
            break;
        case PRODUCT_TYPES.TECH:
            tag = 'Tech';
            defaultPrimaryCta = 'Buy';
            break;
        case PRODUCT_TYPES.PRODUCT:
            tag = 'Product';
            defaultPrimaryCta = 'Buy';
            break;
        case PRODUCT_TYPES.PACKAGE:
            tag = 'Package';
            break;
    }

    const onUnarchive = async () => {
        await saveProduct(dispatcher, navigate, token, product.id, { archived: false });
    }

    return (
        <Card
            variant={variant}
            title={product.title}
            description={product.description}
            seller={product.seller}
            tag={tag}
            price={product.price}
            priceDescription={product.price_description}
            discountedPrice={discountedPrice}
            date={product.event_date && moment(product.event_date).tz(moment.tz.guess()).format('MMM D h:mm a')}
            imgUrl={product.image_url || OneOnOneImg}
            primaryCta={primaryCta || defaultPrimaryCta}
            primaryCtaAction={primaryCtaAction}
            secondaryCta={secondaryCta}
            secondaryCtaAction={secondaryCtaAction}
            archived={product.archived}
            onUnarchive={onUnarchive}
            miniProfile={miniProfile}
            bookingTarget={product.booking_target}
            bookingTargetType={product.booking_target_type}
            profile={product.seller}
        />
    );
}
