// src/components/Filters.tsx
import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, TextField, Box, Typography, InputAdornment, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_FILTERS, SET_FILTERS } from '../../store/actions';
import { INSURANCES } from '../../utils/insurance';
import { LANGUAGES } from '../../utils/languages';
import { MINORITY_GROUPS } from '../../utils/groups';
import { modifyReimbursementLabels } from '../../components/product/utils/format-reimbursement';
import { fetchLocations } from '../../clients/location';
import { State } from '../../types';
import Chip from '../../components/common/chip';

const Filters = () => {
    const dispatcher = useDispatch();

    const user = useSelector((state: State) => state.account.user);
    const token = useSelector((state: State) => state.account.token);

    const keyTerm = useSelector((state: State) => state.search.filters.keyTerm);
    const languages = useSelector((state: State) => state.search.filters.languages);
    const minorityGroups = useSelector(
        (state: State) => state.search.filters.minorityGroups,
    );
    const topics = useSelector((state: State) => state.search.filters.topics);
    const productTypes = useSelector(
        (state: State) => state.search.filters.productTypes,
    );
    const virtual = useSelector((state: State) => state.search.filters.virtual);
    const inPerson = useSelector((state: State) => state.search.filters.inPerson);
    const radius = useSelector((state: State) => state.search.filters.radius);
    const zip = useSelector((state: State) => state.search.filters.zip);
    const insurances = useSelector((state: State) => state.search.filters.insurances);
    const pricing = useSelector((state: State) => state.search.filters.pricing);
    const locationsServed = useSelector((state: State) => state.search.filters.locationsServed);
    const [locations, setLocations] = useState([]);

    const setFilters = (filters) => {
        dispatcher({
            type: SET_FILTERS,
            payload: filters,
        });
    };
    
    const resetFilters = () => {
        dispatcher({
        type: RESET_FILTERS,
        payload: {},
        });
    };
    
    const fetchAndSetLocations = async () => {
        const locations = await fetchLocations(token);
    
        if (locations) {
            setLocations(locations);
        }
    };
    
    useEffect(() => {
        fetchAndSetLocations();
    }, []);

    const ROLES = [
        'Fertility Support',
        'Birth Doula',
        'Childbirth / Infant Care Education',
        'Lactation / Feeding',
        'Postpartum Doula',
        'Sleep Support',
        'Mental Wellness & Relationships',
        'Child Development & Behavior',
        'Physical Wellness / Perinatal Fitness',
        'Nutrition',
    ];

    const PRODUCT_TYPES = [
        "1-on-1 support / coaching",
        "Live group learning",
        "Support group",
        "On-demand learning",
        "Tech solution",
        "Physical product",
    ];

    return (
        <Box sx={{ 
            padding: 2, 
        }}>
        <Typography variant="h6">What sort of support are you seeking?</Typography>
        <FormGroup>
            {ROLES.map((role) => (
                <FormControlLabel
                    key={role}
                    control={
                    <Checkbox
                        checked={topics.includes(role)}
                        onChange={(e) => {
                        if (e.target.checked) {
                            setFilters({ topics: [...topics, role] });
                        } else {
                            setFilters({
                                topics: topics.filter((t) => t !== role),
                            });
                        }
                        }}
                    />
                    }
                    label={role}
                />
            ))}
        </FormGroup>

        <Typography variant="h6" sx={{ marginTop: 2 }}>What's your preference for care delivery?</Typography>
        <FormGroup>
            <FormControlLabel
                control={
                <Checkbox
                    checked={!!virtual}
                    onChange={(e) =>
                    setFilters({ virtual: e.target.checked })
                    }
                    sx={{
                    '&.Mui-checked': {
                        color: '#5371ff',
                    },
                    }}
                />
                }
                label="Virtual"
            />
            <FormControlLabel
                control={
                <Checkbox
                    checked={!!inPerson}
                    onChange={(e) =>
                    setFilters({ inPerson: e.target.checked })
                    }
                    sx={{
                    '&.Mui-checked': {
                        color: '#5371ff',
                    },
                    }}
                />
                }
                label="In Person"
            />
        </FormGroup>

        {!!inPerson && (
            <>
                <Typography variant="h6" sx={{ marginTop: 2 }}>Areas Served In-Person</Typography>
                <Autocomplete
                    multiple
                    id="locations_served"
                    options={locations.map((option) => option.name)}
                    value={locationsServed}
                    renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={option}
                            color="primary"
                            label={option}
                            tagProps={{ ...getTagProps({ index }) }}
                        />
                    ))
                    }
                    sx={{
                    width: '100%',
                    mt: 2,
                    }}
                    renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" />
                    )}
                    onChange={(event, newValue) => {
                    setFilters({ locationsServed: newValue });
                    }}
                />
            </>
        )}

        <Typography variant="h6" sx={{ marginTop: 2 }}>Type of Services Offered</Typography>
        <FormGroup>
            {PRODUCT_TYPES.map((productType) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={productTypes.includes(productType)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setFilters({
                                        productTypes: [...productTypes, productType],
                                    });
                                } else {
                                    setFilters({
                                        productTypes: productTypes.filter(
                                            (t) => t !== productType,
                                        ),
                                    });
                                }
                            }}
                            sx={{
                                '&.Mui-checked': {
                                    color: '#5371ff',
                                },
                            }}
                        />
                    }
                    label={productType}
                />
            ))}
        </FormGroup>

        <Typography variant="h6" sx={{ marginTop: 2 }}>Do you plan to pay with any of the following?</Typography>
        <FormGroup>
            {INSURANCES.map((insurance) => (
                <FormControlLabel
                    key={insurance}
                    control={
                        <Checkbox
                        checked={insurances.includes(insurance)}
                        onChange={(e) => {
                            if (e.target.checked) {
                            setFilters({
                                insurances: [...insurances, insurance],
                            });
                            } else {
                            setFilters({
                                insurances: insurances.filter(
                                (t) => t !== insurance,
                                ),
                            });
                            }
                        }}
                        />
                    }
                    label={modifyReimbursementLabels([insurance])}
                />
            ))}
        </FormGroup>

        <Typography variant="h6" sx={{ marginTop: 2 }}>Search supplier profiles by any keyword</Typography>
        <TextField
            id="search-key-term"
            placeholder="Keyword..."
            variant="outlined"
            onChange={(e) => setFilters({ keyTerm: e.target.value })}
            value={keyTerm}
            size="small"
            sx={{ width: '100%' }}
        />

        <Typography variant="h6" sx={{ marginTop: 2 }}>Languages spoken</Typography>
        <Autocomplete
              multiple
              id="tags-filled"
              options={LANGUAGES.map((option) => option)}
              value={languages}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option}
                    color="primary"
                    label={option}
                    tagProps={{ ...getTagProps({ index }) }}
                  />
                ))
              }
              sx={{
                width: '100%',
                mt: 2,
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
              onChange={(event, newValue) => {
                setFilters({ languages: newValue });
              }}
        />

        <Typography variant="h6" sx={{ marginTop: 2 }}>Experienced with these groups</Typography>
        <FormGroup>
            {MINORITY_GROUPS.map((group) => (
                <FormControlLabel
                    key={group}
                    control={
                        <Checkbox
                        checked={minorityGroups.includes(group)}
                        onChange={(e) => {
                            if (e.target.checked) {
                            setFilters({
                                minorityGroups: [...minorityGroups, group],
                            });
                            } else {
                            setFilters({
                                minorityGroups: minorityGroups.filter(
                                (t) => t !== group,
                                ),
                            });
                            }
                        }}
                        />
                    }
                    label={group}
                />
            ))}
        </FormGroup>
        </Box>
    );
};

export default Filters;
