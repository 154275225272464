import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import {
  SET_SESSION,
  ADD_SESSION_ID,
  RESET_SESSION_IDS,
} from '../../store/actions';
import SessionCard from '../../components/session/session-card';
import SessionRow from '../../components/session/session-row';
import GroupSessionCard from '../../components/group-session/group-session-card';
import GroupSessionRow from '../../components/group-session/group-session-row';
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    Modal,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { get } from '../../services/api.services';
import { ACCOUNT_TYPES, API } from '../../constants';
import { NoResults, PageTitle } from '../sessions/sessions.style';
import FolderCard, {
  FOLDER_VARIANTS,
} from '../../components/folder/folder-card';
import Invite from '../../components/folder/invite';
import { sessionIdsReadyToSchedule } from '../../utils/sessions';
import Module from './module';
import NessleModal from '../../components/common/modal';

function ExpertDashboard() {
  const dispatcher = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.account.user);
  const token = useSelector((state) => state.account.token);
  const sessionIds = useSelector((state) => state.sessions.sessionIds);
  const sessionsById = useSelector((state) => state.sessions.sessionsById);

  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openTestimonialModel, setOpenTestimonialModal] = useState(false);
  const [openDealModal, setOpenDealModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [folders, setFolders] = useState([]);

  const fetchSessions = async () => {
    const result = await get(`${API.SESSION}`, token);

    if (result.status === 403 || result.status === 401) {
      navigate('/login/experts');
    } else if (result.status === 200) {
      dispatcher({
        type: RESET_SESSION_IDS,
        payload: {},
      });

      result.data.forEach((session) => {
        dispatcher({
          type: SET_SESSION,
          payload: {
            session,
          },
        });
        dispatcher({
          type: ADD_SESSION_ID,
          payload: {
            session,
          },
        });
      });
    } else {
    }
  };

  const fetchProducts = async () => {
    const result = await get(
      `${API.PRODUCT}?seller_id=${user.profile.id}&product_type=group`,
      token,
    );

    if (result.status === 403 || result.status === 401) {
      navigate('/login/experts');
    } else if (result.status === 200) {
      setProducts(result.data.products);
    } else {
      // TODO: Handle errors
    }
  };

  const fetchFolders = async () => {
    const result = await get(API.FOLDER, token);

    if (result.status === 403 || result.status === 401) {
      navigate('/login/experts');
    } else if (result.status === 200) {
      setFolders(result.data);
    } else {
      // TODO: Handle errors
    }
  };

  const closeInviteModal = () => {
    setOpenInviteModal(false);
    fetchFolders();
  };

  const upcomingGroupSessions = products.filter(
    (product) =>
      moment(product.event_date) >=
      moment().subtract(product.duration || 30, 'minutes'),
  );
  const finishedGroupSessions = products.filter(
    (product) =>
      moment(product.event_date) <
      moment().subtract(product.duration || 30, 'minutes'),
  );

  useEffect(() => {
    fetchSessions();
    fetchProducts();
    fetchFolders();
  }, []);

  const filteredSessionIds = sessionIdsReadyToSchedule(
    sessionIds,
    sessionsById,
  );
  const sessionRequests = filteredSessionIds.filter(
    (id) => !sessionsById[id].canceled && !sessionsById[id].session_date,
  );
  const pendingSessions = sessionIds.filter(
    (id) =>
      !sessionsById[id].canceled &&
      moment(sessionsById[id].session_date) > moment().subtract(30, 'minutes'),
  );
  const finishedSessions = sessionIds.filter(
    (id) =>
      sessionsById[id].canceled ||
      (sessionsById[id].session_date &&
        moment(sessionsById[id].session_date) <
          moment().subtract(30, 'minutes')),
  );

    return (
        <Container maxWidth="md">
            <Box>
                <PageTitle>
                    Enhance your profile
                </PageTitle>
                <Grid container spacing={2}>
                  <Grid
                    key="profile"
                    item
                    xs={12}
                    md={6}
                    onClick={() => navigate('/profile')}
                  >
                    <Paper elevation={0}>
                      <Box p={2}>
                        <Typography variant="h6">
                          Build / edit my profile 
                        </Typography>
                        <Box mt={2}>
                          <Button variant="contained">Set up profile</Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid
                    key="products"
                    item
                    xs={12}
                    md={6}
                    onClick={() => navigate('/storefront')}
                  >
                    <Paper elevation={0}>
                      <Box p={2}>
                        <Typography variant="h6">
                          Add your product or service 
                        </Typography>
                        <Box mt={2}>
                          <Button variant="contained">Build my storefront</Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid
                    key="testimonial"
                    item
                    xs={12}
                    md={6}
                    onClick={() => setOpenTestimonialModal(true)}
                  >
                    <Paper elevation={0}>
                      <Box p={2}>
                        <Typography variant="h6">
                          Add a client testimonial
                        </Typography>
                        <Box mt={2}>
                          <Button variant="contained">Get started</Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid
                    key="deal"
                    item
                    xs={12}
                    md={6}
                    onClick={() => setOpenDealModal(true)}
                  >
                    <Paper elevation={0}>
                      <Box p={2}>
                        <Typography variant="h6">
                          Add a special deal for Parentswarm users
                        </Typography>
                        <Box mt={2}>
                          <Button variant="contained">Learn More</Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
                <PageTitle>
                    Amplify your Parentswarm Presence
                </PageTitle>
                <Grid container spacing={2}>
                    <Grid
                        key="ambassador"
                        item
                        xs={12}
                        md={6}
                        onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSdnYk9rYFOFp0PgqBEJ9Hxau7ST-zvdLBYbYwL6-xWFbx0zIQ/viewform?usp=sf_link', '_blank')}
                    >
                        <Paper elevation={0}>
                            <Box p={2}>
                            <Typography variant="h6">
                                Be a Parentswarm Ambassador
                            </Typography>
                            <Box mt={2}>
                                <Button variant="contained">Learn More</Button>
                            </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid
                        key="bonus-kit-submission"
                        item
                        xs={12}
                        md={6}
                        onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfRdHrVN4YGVd4xqhaD661gjyQTQfiNbbW79Ly8RI9n_qer5A/viewform?usp=sf_link', '_blank')}
                    >
                        <Paper elevation={0}>
                            <Box p={2}>
                            <Typography variant="h6">
                                Submit a resource to the Parentswarm bonus kit
                            </Typography>
                            <Box mt={2}>
                                <Button variant="contained">Learn More</Button>
                            </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid
                        key="socials"
                        item
                        xs={12}
                        md={6}
                        onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLScnhuLJAyIFEnhlV7IOJM7fiF2INIl7Z8OVglofZy2BXDITGQ/viewform?usp=sf_link', '_blank')}
                    >
                        <Paper elevation={0}>
                            <Box p={2}>
                            <Typography variant="h6">
                                Get featured on our social channels
                            </Typography>
                            <Box mt={2}>
                                <Button variant="contained">Get Featured</Button>
                            </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                {/*<Box p={2} borderRadius={2} sx={{ backgroundColor: 'secondary.light' }}>
                    <Grid container spacing={2}>
                        {!user.profile?.onboarding_checklist["module-profile"] && (
                            <Grid item xs={12} sm={6}>
                                <Module
                                    title={user.profile.unlisted ? "Review and publish your profile" : "Review your profile"}
                                    category="Get Up and Running"
                                    description="Edit your profile and then publish it when you're ready for it to be suggested to parents!"
                                    minutes={10}
                                    moduleKey="profile"
                                    modulePath="/more/profile"
                                />
                            </Grid>
                        )}
                        {!user.profile?.onboarding_checklist["module-review"] && (
                            <Grid item xs={12} sm={6}>
                                <Module
                                    title={`Get your “verified” badge!`}
                                    category="Stand Out"
                                    description={
                                        <div>
                                            {`To mark your listing page as “verified,” we ask for at least${' '}
                                            one public review or testimonial about your services. Invite a past client${' '}
                                            to submit a public review for you by sending them this link: `}
                      <a href="bit.ly/parentswarmreview" target="_blank">
                        bit.ly/parentswarmreview
                      </a>
                      {`, or fill it out yourself with an authentic review you've already received${' '}
                                            online (via Google, your Facebook page, etc.).  This is totally optional, but it’s${' '}
                                            highly recommended to increase your visibility and trust.`}
                                        </div>
                                    }
                                    minutes={2}
                                    moduleKey="review"
                                    moduleLink="http://bit.ly/parentswarmreview"
                                />
                            </Grid>
                        )}
                        {!user.profile?.onboarding_checklist["module-deal"] && (
                            <Grid item xs={12} sm={6}>
                                <Module
                                    title="Add a special deal for Parentswarm users"
                                    category="Stand Out"
                                    description={
                                        <div>
                                            Add a special offer code for parents who find you via our site.{' '}
                                            Whether it's a % off, a special freebie, or a certain $ discount, if{' '}
                                            you create a special code or offer for the customers who find you via Parentswam,{' '}
                                            you'll see an uptick in inbound interest--and you'll be able to track how our site is{' '}
                                            helping bring customers your way. Add your code to the "Leads Settings" section of your profile{' '}
                                            by describing your special offer for Parentswarm parents.
                                        </div>
                                    }
                                    minutes={1}
                                    moduleKey="deal"
                                    modulePath="/more/profile#lead-settings"
                                />
                            </Grid>
                        )}
                        {!user.profile?.onboarding_checklist["module-dbk"] && (
                            <Grid item xs={12} sm={6}>
                                <Module
                                    title="Contribute a resource to the digital bonus kit"
                                    category="Stand Out"
                                    description={
                                        <div>
                                            By submitting your free branded PDF guide, on-demand video, audio recording, or other resource to our digital bonus kit{' '}
                                            for parent subscribers, you'll position yourself as a trusted resource{' '}
                                            and elevate your visibility within our platform. We offer our digital bonus{' '}
                                            kit as a perk for parents who join with referral codes or as VIP members, and{' '}
                                            we’re always looking to make it as valuable as possible.
                                        </div>
                                    }
                                    minutes={5}
                                    moduleKey="dbk"
                                    moduleLink="https://docs.google.com/forms/d/e/1FAIpQLSfRdHrVN4YGVd4xqhaD661gjyQTQfiNbbW79Ly8RI9n_qer5A/viewform"
                                />
                            </Grid>
                        )}
                        {!user.profile?.onboarding_checklist["module-samples"] && (
                            <Grid item xs={12} sm={6}>
                                <Module
                                    title="Contribute sample(s) to our Brand Ambassador and Parent Gift mailers"
                                    category="Stand Out"
                                    description={
                                        <div>
                                            If your company makes physical goods, we’d love to collect samples from you{' '}
                                            that we can include with VIP Parent Member welcome mailers, the gift baskets we{' '}
                                            give out at charity events, the thank-you presents we mail to Brand Ambassadors,{' '}
                                            and the welcome kits we send to doctors and community leaders who can help to spread{' '}
                                            the word about your brand.
                                        </div>
                                    }
                                    minutes={5}
                                    moduleKey="samples"
                                    moduleLink="https://docs.google.com/forms/d/e/1FAIpQLScnhuLJAyIFEnhlV7IOJM7fiF2INIl7Z8OVglofZy2BXDITGQ/viewform"
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>*/}
            {/*sessionRequests.length > 0 && (
                <>
                    <PageTitle>
                        Session Requests
                    </PageTitle>
                    <Grid container spacing={2}>
                        {sessionRequests.map((id) => (
                            <Grid key={id} item xs={12} sm={6}>
                                <SessionCard
                                    session={sessionsById[id]}
                                    variant={sessionsById[id].parent_profile.id === user.profile.id ? ACCOUNT_TYPES.PARENT : ACCOUNT_TYPES.EXPERT}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )*/}
            {/*!user.profile?.onboarding_checklist['module-deal'] && (
              <Grid item xs={12}>
                <Module
                  title="Add a special deal for Parentswarm users"
                  category="Stand Out"
                  description={
                    <div>
                      Add a special offer code for parents who find you via our
                      site. Whether it's a % off, a special freebie, or a
                      certain $ discount, if you create a special code or offer
                      for the customers who find you via Parentswam, you'll see
                      an uptick in inbound interest--and you'll be able to track
                      how our site is helping bring customers your way. Add your
                      code to the "Leads Settings" section of your profile by
                      describing your special offer for Parentswarm parents.
                    </div>
                  }
                  minutes={1}
                  moduleKey="deal"
                  modulePath="/more/profile#leads-settings"
                />
              </Grid>
            )*/}
            {/*!user.profile?.onboarding_checklist['module-dbk'] && (
              <Grid item xs={12}>
                <Module
                  title="Contribute a resource to the digital bonus kit"
                  category="Stand Out"
                  description={
                    <div>
                      By submitting your free branded PDF guide, on-demand
                      video, audio recording, or other resource to our digital
                      bonus kit for parent subscribers, you'll position yourself
                      as a trusted resource and elevate your visibility within
                      our platform. We offer our digital bonus kit as a perk for
                      parents who join with referral codes or as VIP members,
                      and we’re always looking to make it as valuable as
                      possible.
                    </div>
                  }
                  minutes={5}
                  moduleKey="dbk"
                  moduleLink="https://docs.google.com/forms/d/e/1FAIpQLSfRdHrVN4YGVd4xqhaD661gjyQTQfiNbbW79Ly8RI9n_qer5A/viewform"
                />
              </Grid>
            )}
            {!user.profile?.onboarding_checklist['module-samples'] && (
              <Grid item xs={12}>
                <Module
                  title="Contribute sample(s) to our Brand Ambassador and Parent Gift mailers"
                  category="Stand Out"
                  description={
                    <div>
                      If your company makes physical goods, we’d love to collect
                      samples from you that we can include with VIP Parent
                      Member welcome mailers, the gift baskets we give out at
                      charity events, the thank-you presents we mail to Brand
                      Ambassadors, and the welcome kits we send to doctors and
                      community leaders who can help to spread the word about
                      your brand.
                    </div>
                  }
                  minutes={5}
                  moduleKey="samples"
                  moduleLink="https://docs.google.com/forms/d/e/1FAIpQLScnhuLJAyIFEnhlV7IOJM7fiF2INIl7Z8OVglofZy2BXDITGQ/viewform"
                />
              </Grid>
            )*/}
      {/*sessionRequests.length > 0 && (
        <>
          <PageTitle>Session Requests</PageTitle>
          <Grid container spacing={2}>
            {sessionRequests.map((id) => (
              <Grid key={id} item xs={12} sm={6}>
                <SessionCard
                  session={sessionsById[id]}
                  variant={
                    sessionsById[id].parent_profile.id === user.profile.id
                      ? ACCOUNT_TYPES.PARENT
                      : ACCOUNT_TYPES.EXPERT
                  }
                />
              </Grid>
            ))}
          </Grid>
        </>
      )*/}
      {/*<PageTitle>
                Upcoming Sessions
            </PageTitle>
            <Grid container spacing={2}>
                {pendingSessions.length === 0 && upcomingGroupSessions.length === 0 && (
                    <Grid item xs={12}>
                        <NoResults>You don't have any pending sessions yet</NoResults>
                    </Grid>
                )}
                {pendingSessions.map((id) => (
                    <Grid key={id} item xs={12} sm={6}>
                        <SessionCard
                            session={sessionsById[id]}
                            variant={sessionsById[id].parent_profile.id === user.profile.id ? ACCOUNT_TYPES.PARENT : ACCOUNT_TYPES.EXPERT}
                        />
                    </Grid>
                ))}
                {upcomingGroupSessions.map((product) => (
                    <Grid key={product.id} item xs={12} sm={6}>
                        <GroupSessionCard
                            product={product}
                            variant={user.profile.profile_type}
                        />
                    </Grid>
                ))}
            </Grid>*/}
      <Modal open={openInviteModal} onClose={closeInviteModal}>
        <Invite onClose={closeInviteModal} />
      </Modal>
      <Modal open={openTestimonialModel} onClose={() => setOpenTestimonialModal(false)}>
        <NessleModal title="Add a client testimonial" onClose={() => setOpenTestimonialModal(false)}>
          <Typography variant="body1">
            To mark your listing page as “verified,” we ask for at least one public review or testimonial{' '}
            about your services. Invite a past client to submit a public review for you by sending them{' '}
            this link: <Link onClick={() => window.open("https://bit.ly/parentswarmreview", "_blank")}>bit.ly/parentswarmreview</Link> or you may fill it{' '}
            out for them based on a review you’ve already collected.
          </Typography>
        </NessleModal>
      </Modal>
      <Modal open={openDealModal} onClose={() => setOpenDealModal(false)}>
        <NessleModal title="Add a special deal for Parentswarm users" onClose={() => setOpenTestimonialModal(false)}>
          <Typography variant="body1">
              Add a special offer code for parents who find you via our{' '}
              site. Whether it's a % off, a special freebie, or a{' '}
              certain $ discount, if you create a special code or offer{' '}
              for the customers who find you via Parentswam, you'll see{' '}
              an uptick in inbound interest--and you'll be able to track{' '}
              how our site is helping bring customers your way. <b>Add your{' '}
              code to the "Leads Settings" section of your profile</b> by{' '}
              describing your special offer for Parentswarm parents.
          </Typography>
        </NessleModal>
      </Modal>
      </Box>
    </Container>
  );
}

export default ExpertDashboard;

/*
ARCHIVE IN CASE WE WANT TO ADD THIS BACK

<Box display="flex" alignItems="center">
                <PageTitle>
                    My Clients
                </PageTitle>
                {folders.length > 0 && (
                    <Box ml={1}>
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => setOpenInviteModal(true)}
                        >
                            + Add Client
                        </Button>
                        <Button
                            variant="text"
                            style={{ color: '#000000', textTransform: 'none', textDecoration: 'underline', marginLeft: 5 }}
                            onClick={() => {
                                navigate('/clients')
                            }}
                        >
                            View All
                        </Button>
                    </Box>
                )}
            </Box>
            <Grid container spacing={2}>
                {folders.length === 0 && (
                    <Grid item xs={12}>
                        <NoResults>
                            Start managing your clients on Nessle
                            <Box mt={2}>
                                <Button variant="contained" color="primary" onClick={() => setOpenInviteModal(true)}>
                                    Invite a client
                                </Button>
                            </Box>
                        </NoResults>
                    </Grid>
                )}
                {folders.filter((folder) => folder.folder_type === 'CLIENT').slice(0, 4).map((folder) => (
                    <Grid key={folder.id} item xs={12} md={6}>
                        <FolderCard
                            folder={folder}
                            variant={folder.owner.id === user.profile.id ? FOLDER_VARIANTS.OWNER : FOLDER_VARIANTS.MEMBER}
                        />
                    </Grid>
                ))}
            </Grid>


            <Box sx={{ mb: 2 }}>
                <PageTitle>
                    Recent Sessions
                </PageTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            {finishedSessions.length === 0 && finishedGroupSessions.length === 0 && (
                                <Grid item xs={12}>
                                    <NoResults>You don't have completed sessions yet</NoResults>
                                </Grid>
                            )}
                            {finishedSessions.map((id) => (
                                <SessionRow
                                    key={id}
                                    session={sessionsById[id]}
                                    variant={sessionsById[id].parent_profile.id === user.profile.id ? ACCOUNT_TYPES.PARENT : ACCOUNT_TYPES.EXPERT}
                                />
                            ))}
                            {finishedGroupSessions.map((product) => (
                                <Grid key={product.id} item xs={12}>
                                    <GroupSessionRow
                                        product={product}
                                        variant={user.profile_type}
                                    />
                                </Grid>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

*/
