import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";

import FolderIcon from "../../assets/icons/folder.svg";
import {
  ProductCategories,
  ProductContainer,
  ProductLabel,
  ProductTitle,
  ProductValue,
  ReimbursementDetailLine,
} from "./product.style";
import MiniProfile from "../session/mini-profile";
import MeetingLink from "../session/meeting-link";
import { getPriceAfterDiscounts } from "../../utils/coupons";
import ResourcePreview from "../resource/resource-preview";
import { PRODUCT_TYPES } from "../../constants";
import ProductPreview from "./product-preview";
import ProductDetailLine from "./product-detail-line";
import { capitalizeFirstLetter } from "../../utils/stringHelpers";
import { CANCELATION_POLICIES } from "../../constants";
import ReimbursementLabel from "./components/reimbursement-label";
import { modifyReimbursementLabels } from "./utils/format-reimbursement";

export default function ProductDisplay({ product, hasPurchased }) {
    const user = useSelector((state) => state.account.user);
    const discountedPrice = getPriceAfterDiscounts(product, user?.profile.discount?.coupon, user?.profile?.feature_flags.tracks);

  return (
    <ProductContainer>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box sx={{ pl: 2, pr: 2 }}>
            <Avatar
              src={product.image_url}
              sx={{ width: "100%", height: 200, borderRadius: 4 }}
              variant="square"
            />
            <Box sx={{ mt: 1, mb: 1 }}>
              <Typography variant="h5">{product.title}</Typography>
            </Box>
            <Box sx={{ mt: 1, mb: 1 }}>
              <ProductCategories style={{ border: "none" }}>
                <img src={FolderIcon} alt="categories" width={15} />
                &nbsp;
                {product.categories.split(",").join(", ")}
              </ProductCategories>
            </Box>
            <Box sx={{ pb: 1 }}>
              <MiniProfile profile={product.seller} />
            </Box>
            {((hasPurchased && product.product_type === PRODUCT_TYPES.GROUP) ||
              product.product_type === PRODUCT_TYPES.SINGLE) &&
              product.location && (
                <Box sx={{ mt: 1, mb: 1, pb: 1 }}>
                  <ProductLabel>In-Person Location</ProductLabel>
                  <ProductValue>{product.location}</ProductValue>
                </Box>
              )}
            {hasPurchased && product.link && (
              <Box sx={{ mt: 1, mb: 1, borderTop: "1px solid #E8E8E8", pb: 1 }}>
                <ProductLabel>Meeting Link</ProductLabel>
                <Box>
                  <MeetingLink link={product.link} />
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: { xs: "block", sm: "none" },
                mt: 1,
                mb: 1,
                borderTop: "1px solid #E8E8E8",
                pb: 1,
              }}
            >
              <ProductLabel>About</ProductLabel>
              <Box>{product.description}</Box>
            </Box>
            {product.event_date && (
              <ProductDetailLine
                label="Date"
                value={moment(product.event_date)
                  .tz(moment.tz.guess())
                  .format("MMM D, h:mm a z")}
              />
            )}
            <ProductDetailLine
              label="Price"
              value={
                product.price_description ? product.price_description : (
                discountedPrice !== product.price ? (
                  <>
                    <span
                      style={{ opacity: "0.7", textDecoration: "line-through" }}
                    >
                      ${product.price.toLocaleString()}
                    </span>{" "}
                    {discountedPrice === 0
                      ? "Free"
                      : `$${discountedPrice.toLocaleString()}`}
                  </>
                ) : (
                  <span>${product.price.toLocaleString()}</span>
                )
              )}
            />
            {product.additional_payment_plans_offered.length === 0 &&
              discountedPrice > 0 && (
                <ProductDetailLine
                  label="Payment Terms"
                  value={product.default_payment_plan.description}
                />
              )}
            {(product.product_type === PRODUCT_TYPES.SINGLE ||
              product.product_type === PRODUCT_TYPES.GROUP) && product.duration && (
                <ProductDetailLine
                  label="Duration"
                  value={
                    product.duration
                      ? `${product.duration} min${
                          product.duration > 1 ? "s" : ""
                        }`
                      : ""
                  }
                />
              )}
            {product.product_type !== PRODUCT_TYPES.RESOURCE &&
              (product.virtual || product.in_person) && (
                <ProductDetailLine
                  label="Location"
                  value={`${product.virtual ? "Virtual" : ""}${
                    product.in_person
                      ? `${product.virtual ? ", In Person" : "In Person"}`
                      : ""
                  }`}
                />
              )}
            {product.insurance_accepted.length > 0 && product.price > 0 && (
              <>
                <ProductDetailLine
                  label={
                    <ReimbursementLabel
                      insuranceOptions={modifyReimbursementLabels(
                        product.insurance_accepted
                      )}
                    />
                  }
                  value={modifyReimbursementLabels(
                    product.insurance_accepted
                  ).join(", ")}
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              pl: 2,
              pr: 2,
              borderLeft: "1px solid #e8e8e8",
              minHeight: "100%",
            }}
          >
            <Box>
              <ProductLabel>About</ProductLabel>
              <Box>{product.description}</Box>
            </Box>
            {product.resources.length > 0 && (
              <Box mt={1}>
                <ProductLabel>Resources Included</ProductLabel>
                <Stack spacing={1} mt={1}>
                  {product.resources
                    .sort((a, b) => a.order - b.order)
                    .map((product_resource) => (
                      <ResourcePreview
                        key={product_resource.id}
                        resource={product_resource.resource}
                        showThumbnail={false}
                        interactable={false}
                      />
                    ))}
                </Stack>
              </Box>
            )}
            {product.child_products.length > 0 && (
              <Box mt={1}>
                <ProductLabel>What's Included</ProductLabel>
                <Stack spacing={1} mt={1}>
                  {product.child_products
                    .sort((a, b) => a.order - b.order)
                    .map((child_product) => (
                      <ProductPreview
                        product={child_product.child_product}
                        interactable={false}
                      />
                    ))}
                </Stack>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </ProductContainer>
  );
}
