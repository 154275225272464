import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import Button from '../../components/common/button';
import { SET_FILTERS } from '../../store/actions';

function Topic({ topic, img, displayText, cancelNavigate }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const onClickTopic = (topic) => {
        dispatcher({
            type: SET_FILTERS,
            payload: {
                topics: [topic],
            },
        });

        if (!cancelNavigate) {
            navigate('/search');
        }
    };

    return (
        <Box
            onClick={() => onClickTopic(topic)}
            sx={{ cursor: 'pointer' }}
            mb={2}
        >
            <img src={img} width="100%" style={{ borderRadius: '8px' }} />
            <Box mt={-7} sx={{ textAlign: 'center' }}>
                <Button shade="secondary">
                    {displayText || topic}
                </Button>
            </Box>
        </Box>
    );
}

export default Topic;