import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Alert, Box, Modal } from '@mui/material';

import Header from '../header';
import Sidebar from '../sidebar';
import LoggedOutHeader from '../header/logged-out';
import LoggedOutFooter from '../footer/logged-out';
import SignatureForm from '../signature/signature-form';
import ProductPurchase from '../product/purchase';
import Subscribe from '../payment/subscribe';
import { PRODUCT_TYPES } from '../../constants';
import VersionAlert from './version-alert';

import {
  SuccessModal,
} from '../header/Header.style';
import Button from '../common/button';
import Posted from '../../assets/images/Posted.png';

import { ACCOUNT_TYPES, PaywallTypes } from '../../constants';
import {
  CLOSE_PRODUCT,
  SET_PAYWALL,
  SET_SHARE_TO_SIGN,
} from '../../store/actions';

export default function Layout() {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const user = useSelector((state) => state.account.user);
  const paywall = useSelector((state) => state.account.paywall);
  const paywall_type = useSelector((state) => state.account.paywall_type);

  const openProduct = useSelector((state) => state.products.openProduct);
  const bookNow = useSelector((state) => state.products.bookNow);
  const productMap = useSelector((state) => state.products.productsById);
  const shareToSign = useSelector((state) => state.shares.shareToSign);

  const closeProduct = () => {
    dispatcher({
      type: CLOSE_PRODUCT,
      payload: {},
    });
  };

  const closePaywall = () => {
    dispatcher({
      type: SET_PAYWALL,
      payload: {
        paywall: false,
      },
    });
  }

  const closeSignModal = () => {
    dispatcher({
      type: SET_SHARE_TO_SIGN,
      payload: { share: undefined },
    });
  };

  const Content = (
    <>
      <Box sx={{ backgroundColor: '#F8F8F8', minHeight: '100%' }}>
          <VersionAlert display={user ? 'block' : 'hidden'} />
          <Outlet />
          <Box sx={{ height: '70px' }} />
        </Box>
        {!user && <LoggedOutFooter />}
    </>
  )

  return (
    <Box sx={{ height: '100%', minHeight: '100vh', display: 'flex' }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        {user ? <Header content={Content} /> : (<><LoggedOutHeader />{Content}</>)}
      </Box>
      <Modal
        open={openProduct}
        onClose={closeProduct}
      >
        <ProductPurchase
            product={productMap[openProduct]}
            onClose={closeProduct}
            skipPreview={bookNow}
        />
      </Modal>
      <Modal
        open={paywall}
        onClose={closePaywall}
      >
        <SuccessModal
          style={{ width: '70%', maxWidth: '800px', height: 'auto', maxHeight: '60%', overflowY: 'scroll' }}
        >
          <Subscribe paywall paywall_type={paywall_type} displayPlans={paywall_type === PaywallTypes.BASE} />
        </SuccessModal>
      </Modal>
      <Modal
            open={!!shareToSign}
            onClose={closeSignModal}
        >
            <SignatureForm share={shareToSign} onClose={closeSignModal} />
        </Modal>
    </Box>
  );
}
