import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Button, Card, Fab, Modal, Stack, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Lottie from 'react-lottie-player';

import { Product } from '../../types';
import { createProduct, saveProduct, fetchProductsBySeller } from '../../clients/product';
import { useAuth } from '../../hooks';
import { SET_LOADING, RESET_PRODUCT_IDS_FOR_SELLER } from '../../store/actions';
import { SuccessModalText, SuccessModalTitle } from '../header/Header.style';
import duplicateLottie from '../../assets/animations/duplicate-lottie.json';

interface ActionBarProps {
    product: Product,
    edit: any,
}

export default function ActionBar({ product, edit }: ActionBarProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { user, token } = useAuth();

    const [duplicateSuccess, setDuplicateSuccess] = useState(false);
    const [duplicatedProductId, setDuplicatedProductId] = useState();

    const toggleFeatured = async () => {
        await saveProduct(dispatcher, navigate, token, product.id, { featured: !product.featured });
    }

    const archive = async () => {
        await saveProduct(dispatcher, navigate, token, product.id, { archived: true });
    };

    const unarchive = async () => {
        await saveProduct(dispatcher, navigate, token, product.id, { archived: false });
    };

    const deleteProduct = async () => {
        const result = await saveProduct(dispatcher, navigate, token, product.id, { deleted: true });

        dispatcher({
            type: SET_LOADING,
            payload: { loading: true },
        });

        dispatcher({
            type: RESET_PRODUCT_IDS_FOR_SELLER,
            payload: { sellerId: user.profile.id },
        });

        await fetchProductsBySeller(dispatcher, navigate, token, user.profile.id, true);

        dispatcher({
            type: SET_LOADING,
            payload: { loading: false },
        });
    };

    const duplicate = async () => {
        const result = await createProduct(dispatcher, navigate, token, {
            title: `${product.title} (Copy)`,
            price: product.price,
            categories: product.categories,
            product_type: product.product_type,
            image: product.image,
            description: product.description,
            event_date: product.event_date,
            max_sales: product.max_sales,
            duration: product.duration,
            in_person: product.in_person,
            virtual: product.virtual,
            link: product.link,
            location: product.location,
            general_access: ['private'],
            resource_ids: product.resources.sort((a, b) => a.order - b.order).map((pr) => pr.resource.id),
            child_product_ids: product.child_products.sort((a, b) => a.order - b.order).map((cp) => cp.child_product.id),
            generate_meeting_link: product.generate_meeting_link,
            workflow: product.workflow,
            default_payment_plan_id: product.default_payment_plan.id,
            additional_payment_plans_offered_ids: product.additional_payment_plans_offered.map((p) => p.id),
            cancelation_policy: product.cancelation_policy,
        });

        if (result.status === 201) {
            setDuplicatedProductId(result.data.id);
            setDuplicateSuccess(true);
        }
    };

    return (
        <Box mt={1} mb={2} display="flex" alignItems="center" justifyContent="flex-start">
            <Stack spacing={2} direction="row">
                <Tooltip title="Edit">
                    <Fab size="small" color="secondary" aria-label="add" onClick={() => edit(product.id)}>
                        <ModeEditOutlineOutlinedIcon />
                    </Fab>
                </Tooltip>
                {product.featured && !product.archived && (
                    <Tooltip title="This service is Featured. Featured services appear first in your Storefront.">
                        <Fab size="small" color="secondary" aria-label="add" onClick={toggleFeatured}>
                            <StarOutlinedIcon />
                        </Fab>
                    </Tooltip>
                )}
                {!product.featured && !product.archived && (
                    <Tooltip title="Turn on to make this service Featured. Featured services appear first in your Storefront.">
                        <Fab size="small" color="secondary" aria-label="add" onClick={toggleFeatured}>
                            <StarBorderOutlinedIcon />
                        </Fab>
                    </Tooltip>
                )}
                {/*} - duplicated services are marked "Private" until you update the access restrictions'>
                    <Fab size="small" color="secondary" aria-label="duplicate" onClick={duplicate}>
                        <ContentCopyIcon />
                    </Fab>
                </Tooltip>*/}
                {!product.archived && (
                    <Tooltip title="Archive">
                        <Fab size="small" color="secondary" aria-label="archive" onClick={archive}>
                            <ArchiveOutlinedIcon />
                        </Fab>
                    </Tooltip>
                )}
                {product.archived && (
                    <Tooltip title="Unarchive">
                        <Fab size="small" color="secondary" aria-label="archive" onClick={unarchive}>
                            <UnarchiveOutlinedIcon />
                        </Fab>
                    </Tooltip>
                )}
                <Tooltip title="Delete">
                    <Fab size="small" color="secondary" aria-label="delete" onClick={deleteProduct}>
                        <DeleteOutlineIcon />
                    </Fab>
                </Tooltip>
            </Stack>
            <Modal
                open={duplicateSuccess}
                onClose={() => setDuplicateSuccess(false)}
            >
                <Card style={{ width: '80%', maxWidth: '300px', margin: '0 auto', marginTop: '10vh' }}>
                    <Box p={2}>
                        <Box display="flex" justifyContent="center">
                            <Lottie
                                loop
                                animationData={duplicateLottie}
                                play
                                style={{ width: 200, height: 200 }}
                            />
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <SuccessModalTitle>Duplicated!</SuccessModalTitle>
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <SuccessModalText>
                                We've set your duplicated service as "Private".{' '}
                                Edit your service to update the access restrictions when you're ready to display it in your storefront.
                            </SuccessModalText>
                        </Box>
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => {
                                    edit(duplicatedProductId);
                                    setDuplicateSuccess(false);
                                }}
                            >
                                Edit
                            </Button>
                        </Box>
                    </Box>
                </Card>
            </Modal>
        </Box>
    );
}
