import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Collapse,
  Drawer,
  List,
  Paper,
  CssBaseline,
} from '@mui/material';

import { ACCOUNT_TYPES } from '../../constants';
import {
  Container,
  Logo,
  Profile,
  ProfileText,
} from './Sidebar.style';
import NessleLogo from '../../assets/images/parentswarm-logo.svg';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ExpandMore from '@mui/icons-material/ExpandMoreRounded';
import ExpandLess from '@mui/icons-material/ExpandLessRounded';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import EditNotificationsOutlinedIcon from '@mui/icons-material/EditNotificationsOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import { stringToColor } from '../../utils/stringHelpers';
import { SET_PAYWALL } from "../../store/actions";
import { PaywallTypes } from '../../constants';
import SidebarItem from './item';

const PATH_MAP = {
  '': 'home',
  'dashboard': 'home',
  'search': 'experts',
  'sessions': 'sessions',
  'group-sessions': 'group-sessions',
  'more': 'billing',
  'support': 'support',
  'clients': 'clients',
  'earnings': 'earnings',
  'resources': 'resources',
  'shares': 'resources',
  'storefront': 'storefront',
  'payment-settings': 'payment-settings',
  'profile': 'profile',
  'billing': 'billing',
  'documentation': 'documentation',
  'notifications': 'notifications',
  'partners': 'partners',
  'tracks': 'tracks',
  'digital-bonus-kit': 'digital-bonus-kit',
};

function Sidebar() {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.account.user);
  const featureFlags = useSelector((state) => state.account.user.profile.feature_flags);

  const [active, setActive] = useState();
  const [expandedSections, setExpandedSections] = useState([]);
  const [openMore, setOpenMore] = useState(false);

  const ACTION_MAP = {
    'home': () => {
      setActive('home');
      navigate('/dashboard');
    },
    'storefront': () => {
      setActive('storefront');
      navigate('/storefront');
    },
    'payment-settings': () => {
      setActive('payment-settings');
      navigate('/payment-settings');
    },
    'clients': () => {
      setActive('clients');
      navigate('/clients');
    },
    'sessions': () => {
      setActive('sessions');
      navigate('/sessions');
    },
    'resources': () => {
      setActive('resources');

      if (user.profile.profile_type === ACCOUNT_TYPES.EXPERT) {
        navigate('/resources');
      } else {
        navigate('/shares');
      }
    },
    'earnings': () => {
      setActive('earnings');
      navigate('/earnings');
    },
    'experts': () => {
      navigate('/search');
      setActive('experts');
    },
    'support': () => {
      navigate('/support');
      setActive('support');
    },
    'more': () => {
      setActive('more');
      navigate('/more');
    },
    'profile': () => {
      setActive('profile');
      navigate('/profile');
    },
    'billing': () => {
      setActive('billing');
      navigate('/more/billing');
    },
    'documentation': () => {
      setActive('documentation');
      navigate('/more/documentation');
    },
    'notifications': () => {
      setActive('notifications');
      navigate('/more/notifications');
    },
    'partners': () => {
      setActive('partners');
      navigate('/partners');
    },
    'tracks': () => {
      setActive('tracks');
      navigate('/tracks');
    },
    'digital-bonus-kit': () => {
      setActive('digital-bonus-kit');
      navigate('/digital-bonus-kit');
    }
  };

  useEffect(() => {
      let paths = location.pathname.split('/');
      setActive(PATH_MAP[paths[paths.length - 1]]);

      if (paths.length > 2 && !expandedSections.includes(paths[paths.length - 2])) {
        toggleExpandedSection(paths[paths.length - 2]);
      }
  }, [location]);

  const toggleExpandedSection = (section) => {
    if (expandedSections.includes(section)) {
      setExpandedSections(expandedSections.filter((s) => s !== section));
    } else {
      setExpandedSections([ ...expandedSections, section ]);
    }
  }

  const openPaywall = () => {
    dispatcher({
        type: SET_PAYWALL,
        payload: {
            paywall: true,
            paywall_type: PaywallTypes.BASE,
        },
    });
  };

  let fullNav = [
    <SidebarItem
      action={ACTION_MAP.home}
      active={active === 'home'}
      Icon={HomeOutlinedIcon}
      text="Home"
    />,
  ];

  const expertNav = [
    <SidebarItem
      action={ACTION_MAP.profile}
      active={active === 'profile'}
      Icon={AccountBoxOutlinedIcon}
      text="Profile"
    />,
    <SidebarItem
        action={ACTION_MAP.billing}
        active={active === 'billing'}
        Icon={CreditCardOutlinedIcon}
        text="Billing & Payouts"
    />,
    <SidebarItem
      action={featureFlags?.digital_bonus_kit ? ACTION_MAP['digital-bonus-kit'] : openPaywall}
      active={active === 'digital-bonus-kit'}
      Icon={DynamicFeedOutlinedIcon}
      text="Digital Bonus Kit"
    />,
    <SidebarItem
          action={ACTION_MAP.storefront}
          active={active === 'storefront'}
          Icon={StorefrontIcon}
          text="My Storefront"
    />,
    /*<SidebarItem
      action={() => toggleExpandedSection('storefront')}
      active={false}
      Icon={StorefrontIcon}
      text="My Storefront"
    >
      {expandedSections.includes('storefront') ? <ExpandLess /> : <ExpandMore />}
    </SidebarItem>,
    <Collapse in={expandedSections.includes('storefront')} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <SidebarItem
          action={ACTION_MAP.storefront}
          active={active === 'storefront'}
          Icon={SellOutlinedIcon}
          text="Services"
          sx={{ pl: 4 }}
        />
        <SidebarItem
          action={ACTION_MAP['payment-settings']}
          active={active === 'payment-settings'}
          Icon={RequestQuoteOutlinedIcon}
          text="Payment Settings"
          sx={{ pl: 4 }}
        />
      </List>
    </Collapse>,
    <SidebarItem
      action={ACTION_MAP.clients}
      active={active === 'clients'}
      Icon={FolderSharedOutlinedIcon}
      text="My Clients"
    />,
    <SidebarItem
      action={ACTION_MAP.sessions}
      active={active === 'sessions'}
      Icon={PeopleOutlinedIcon}
      text="Sessions"
    />,
    <SidebarItem
      action={ACTION_MAP.resources}
      active={active === 'resources'}
      Icon={Inventory2OutlinedIcon}
      text="Resources"
    />,
    <SidebarItem
      action={ACTION_MAP.earnings}
      active={active === 'earnings'}
      Icon={MonetizationOnOutlinedIcon}
      text="Income & Expenses"
    />,
    /*<SidebarItem
        action={ACTION_MAP.support}
        active={active === 'support'}
        Icon={VolunteerActivismOutlinedIcon}
        text="Browse Services"
    />,*/
    <SidebarItem
      action={() => toggleExpandedSection('more')}
      active={false}
      Icon={MoreHorizRoundedIcon}
      text="More"
    >
      {expandedSections.includes('more') ? <ExpandLess /> : <ExpandMore />}
    </SidebarItem>,
    <Collapse in={expandedSections.includes('more')} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <SidebarItem
          action={ACTION_MAP.documentation}
          active={active === 'documentation'}
          Icon={SourceOutlinedIcon}
          text="Documentation"
          sx={{ pl: 4 }}
        />
        <SidebarItem
          action={ACTION_MAP.notifications}
          active={active === 'notifications'}
          Icon={EditNotificationsOutlinedIcon}
          text="Notifications"
          sx={{ pl: 4 }}
        />
      </List>
    </Collapse>,
  ];

  const parentNav = [
    /*<SidebarItem
      action={ACTION_MAP.experts}
      active={active === 'experts'}
      Icon={Diversity1OutlinedIcon}
      text="Browse Resources"
    />,*/
    /*<SidebarItem
      action={ACTION_MAP.support}
      active={active === 'support'}
      Icon={VolunteerActivismOutlinedIcon}
      text="Browse Services"
    />,
    <SidebarItem
      action={ACTION_MAP.sessions}
      active={active === 'sessions'}
      Icon={PeopleOutlinedIcon}
      text="Sessions"
    />,
    <SidebarItem
      action={ACTION_MAP.resources}
      active={active === 'resources'}
      Icon={Inventory2OutlinedIcon}
      text="Resources"
    />,*/
    /*<SidebarItem
      action={ACTION_MAP.partners}
      active={active === 'partners'}
      Icon={HandshakeOutlinedIcon}
      text="Trusted Brands & Discounts"
    />,*/
    <SidebarItem
      action={ACTION_MAP.profile}
      active={active === 'profile'}
      Icon={AccountBoxOutlinedIcon}
      text="Profile"
    />,
    <SidebarItem
      action={ACTION_MAP.billing}
      active={active === 'billing'}
      Icon={CreditCardOutlinedIcon}
      text="Billing & Points Wallet"
    />,
    <SidebarItem
      action={featureFlags?.digital_bonus_kit ? ACTION_MAP['digital-bonus-kit'] : openPaywall}
      active={active === 'digital-bonus-kit'}
      Icon={DynamicFeedOutlinedIcon}
      text="Resources"
    />,
    /*<SidebarItem
      action={() => toggleExpandedSection('more')}
      active={false}
      Icon={MoreHorizRoundedIcon}
      text="More"
    >
      {expandedSections.includes('more') ? <ExpandLess /> : <ExpandMore />}
    </SidebarItem>,
    <Collapse in={expandedSections.includes('more')} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <SidebarItem
          action={ACTION_MAP.billing}
          active={active === 'billing'}
          Icon={CreditCardOutlinedIcon}
          text="Billing"
          sx={{ pl: 4 }}
        />
        <SidebarItem
          action={ACTION_MAP.notifications}
          active={active === 'notifications'}
          Icon={EditNotificationsOutlinedIcon}
          text="Notifications"
          sx={{ pl: 4 }}
        />
      </List>
    </Collapse>,*/
  ];

  if (user.profile.profile_type === ACCOUNT_TYPES.EXPERT) {
    fullNav = fullNav.concat(expertNav);
  } else {
    fullNav = fullNav.concat(parentNav);
  }

  return (
    <>
      <Container>
        <List
          sx={{ width: '100%' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {fullNav}
        </List>
      </Container>
    </>
  );
}

export default Sidebar;
