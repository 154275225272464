import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
} from '@mui/material';

import FileUploader from '../common/file-uploader';
import { photoBank } from '../../utils/photo-bank';
import {
    ModalFormItem,
    ModalFormItemLabel,
    PhotoBankImg,
    PhotoBankImgActive,
} from './product.style';
import { PRODUCT_TYPES } from '../../constants';

export default function Description({
    description,
    setDescription,
    photoKey,
    setPhotoKey,
    photoPreview,
    setPhotoPreview,
    uploadedPhotoUrl,
    productType,
}) {
    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [uploadMode, setUploadMode] = useState(photoKey && photoKey.includes('photo-bank') ? false : true);
    const [showFullBank, setShowFullBank] = useState(false);
    const BANK_PREVIEW_NUM_PHOTOS = 6;

    return (
        <Stack spacing={2} alignItems="center" >
            <ModalFormItem>
                <ModalFormItemLabel>
                    Choose a photo:
                </ModalFormItemLabel>
                {/*<Box display="flex" justifyContent="center" m={2}>
                    <ButtonGroup variant="outlined">
                        <Button
                            variant={uploadMode ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => setUploadMode(true)}
                        >
                            Upload
                        </Button>
                        <Button
                            variant={uploadMode ? 'outlined' : 'contained'}
                            color="primary"
                            onClick={() => setUploadMode(false)}
                        >
                            Photo Bank
                        </Button>
                    </ButtonGroup>
                </Box>*/}
                {!uploadMode && (
                    <>
                        <Grid container spacing={2}>
                            {photoBank.map((bankImg, index) => (
                                <React.Fragment key={bankImg.key}>
                                    {(showFullBank || index < BANK_PREVIEW_NUM_PHOTOS) && (
                                        <Grid item xs={6} sm={4}>
                                            {bankImg.key === photoKey ? (
                                                <PhotoBankImgActive
                                                    src={bankImg.src}
                                                    alt="photo"
                                                />
                                            ) : (
                                                <PhotoBankImg
                                                    src={bankImg.src}
                                                    alt="photo"
                                                    onClick={() => {
                                                        setPhotoKey(bankImg.key);
                                                        setPhotoPreview(bankImg.src);
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}
                        </Grid>
                        <Box sx={{ textAlign: 'center', mt: 1 }}>
                            {showFullBank ? (
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => setShowFullBank(false)}
                                    color="primary"
                                >
                                    Show Less
                                </Button>
                            ) : (
                                <Button
                                    size="small"
                                    variant="text"
                                    onClick={() => setShowFullBank(true)}
                                    color="primary"
                                >
                                    Show All
                                </Button>
                            )}
                        </Box>
                    </>
                )}
                {uploadMode && (
                    <Box>
                        <FileUploader
                            existingFile={uploadedPhotoUrl}
                            pathPrefix="product-pictures"
                            onSuccess={(fileKey, fileSrc) => {
                                setPhotoKey(fileKey);
                                setPhotoPreview(fileSrc);
                            }}
                        />
                    </Box>
                )}
            </ModalFormItem>
            <ModalFormItem>
                <ModalFormItemLabel>
                    About:
                </ModalFormItemLabel>
                <TextField
                    id="details-text"
                    variant="outlined"
                    placeholder="Add any details about what is included here"
                    multiline
                    minRows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    sx={{ width: '100%' }}
                    inputProps={{ maxLength: 1024 }}
                />
            </ModalFormItem>
        </Stack>
    );
}
