import PhotoBankLogo from '../assets/images/photo-bank/photo-bank-ps-logo.png';
import PhotoBank1 from '../assets/images/photo-bank/1.png';
import PhotoBank2 from '../assets/images/photo-bank/2.png';
import PhotoBank3 from '../assets/images/photo-bank/3.png';
import PhotoBank4 from '../assets/images/photo-bank/4.png';
import PhotoBank5 from '../assets/images/photo-bank/5.png';
import PhotoBank6 from '../assets/images/photo-bank/6.png';
import PhotoBank7 from '../assets/images/photo-bank/7.png';
import PhotoBank8 from '../assets/images/photo-bank/8.png';
import PhotoBank9 from '../assets/images/photo-bank/9.png';
import PhotoBank10 from '../assets/images/photo-bank/10.png';
import PhotoBank11 from '../assets/images/photo-bank/11.png';
import PhotoBank12 from '../assets/images/photo-bank/12.png';
import PhotoBank13 from '../assets/images/photo-bank/13.png';
import PhotoBank14 from '../assets/images/photo-bank/14.png';
import PhotoBank15 from '../assets/images/photo-bank/15.png';
import PhotoBank16 from '../assets/images/photo-bank/16.png';
import PhotoBank17 from '../assets/images/photo-bank/17.png';
import PhotoBank18 from '../assets/images/photo-bank/18.png';
import PhotoBank19 from '../assets/images/photo-bank/19.png';
import PhotoBank20 from '../assets/images/photo-bank/20.png';
import PhotoBank21 from '../assets/images/photo-bank/21.png';
import PhotoBankPexels1 from '../assets/images/photo-bank/pexels-1.jpg';
import PhotoBankPexels2 from '../assets/images/photo-bank/pexels-2.jpg';
import PhotoBankPexels3 from '../assets/images/photo-bank/pexels-3.jpg';
import PhotoBankPexels4 from '../assets/images/photo-bank/pexels-4.jpg';
import PhotoBankPexels5 from '../assets/images/photo-bank/pexels-5.jpg';
import PhotoBankPexels6 from '../assets/images/photo-bank/pexels-6.jpg';
import PhotoBankPexels7 from '../assets/images/photo-bank/pexels-7.jpg';
import PhotoBankPexels8 from '../assets/images/photo-bank/pexels-8.jpg';
import PhotoBankPexels9 from '../assets/images/photo-bank/pexels-9.jpg';
import PhotoBankPexels10 from '../assets/images/photo-bank/pexels-10.jpg';
import PhotoBankPexels11 from '../assets/images/photo-bank/pexels-11.jpg';
import PhotoBankPexels12 from '../assets/images/photo-bank/pexels-12.jpg';
import PhotoBankPexels13 from '../assets/images/photo-bank/pexels-13.jpg';
import PhotoBankPexels14 from '../assets/images/photo-bank/pexels-14.jpg';
import PhotoBankPexels15 from '../assets/images/photo-bank/pexels-15.jpg';
import PhotoBankPexels16 from '../assets/images/photo-bank/pexels-16.jpg';
import PhotoBankPexels17 from '../assets/images/photo-bank/pexels-17.jpg';
import PhotoBankPexels18 from '../assets/images/photo-bank/pexels-18.jpg';
import PhotoBankPexels19 from '../assets/images/photo-bank/pexels-19.jpg';
import PhotoBankPexels20 from '../assets/images/photo-bank/pexels-20.jpg';
import PhotoBankBirth1 from '../assets/images/photo-bank/birth-1.png';
import PhotoBankBirth2 from '../assets/images/photo-bank/birth-2.png';
import PhotoBankBirth3 from '../assets/images/photo-bank/birth-3.png';
import PhotoBankBirth4 from '../assets/images/photo-bank/birth-4.png';
import PhotoBankBirth5 from '../assets/images/photo-bank/birth-5.png';
import PhotoBankBirth6 from '../assets/images/photo-bank/birth-6.png';
import PhotoBankBirth7 from '../assets/images/photo-bank/birth-7.png';
import PhotoBankBirth8 from '../assets/images/photo-bank/birth-8.png';
import PhotoBankBirth9 from '../assets/images/photo-bank/birth-9.png';
import PhotoBankBirth10 from '../assets/images/photo-bank/birth-10.png';
import PhotoBankBirth11 from '../assets/images/photo-bank/birth-11.png';
import PhotoBankBirth12 from '../assets/images/photo-bank/birth-12.png';
import PhotoBankBirth13 from '../assets/images/photo-bank/birth-13.png';
import PhotoBankBirth14 from '../assets/images/photo-bank/birth-14.png';
import PhotoBankBirth15 from '../assets/images/photo-bank/birth-15.png';

export const photoBank = [
    /*{
        key: 'product-pictures/photo-bank/photo-bank-ps-logo.png',
        src: PhotoBankLogo,
    },*/
    {
        key: 'product-pictures/photo-bank/pexels-1.jpg',
        src: PhotoBankPexels1,
    },
    {
        key: 'product-pictures/photo-bank/pexels-2.jpg',
        src: PhotoBankPexels2,
    },
    {
        key: 'product-pictures/photo-bank/pexels-9.jpg',
        src: PhotoBankPexels9,
    },
    {
        key: 'product-pictures/photo-bank/pexels-3.jpg',
        src: PhotoBankPexels3,
    },
    {
        key: 'product-pictures/photo-bank/pexels-7.jpg',
        src: PhotoBankPexels7,
    },
    {
        key: 'product-pictures/photo-bank/pexels-8.jpg',
        src: PhotoBankPexels8,
    },
    {
        key: 'product-pictures/photo-bank/pexels-5.jpg',
        src: PhotoBankPexels5,
    },
    {
        key: 'product-pictures/photo-bank/pexels-6.jpg',
        src: PhotoBankPexels6,
    },
    {
        key: 'product-pictures/photo-bank/pexels-10.jpg',
        src: PhotoBankPexels10,
    },
    {
        key: 'product-pictures/photo-bank/pexels-11.jpg',
        src: PhotoBankPexels11,
    },
    {
        key: 'product-pictures/photo-bank/pexels-12.jpg',
        src: PhotoBankPexels12,
    },
    {
        key: 'product-pictures/photo-bank/pexels-13.jpg',
        src: PhotoBankPexels13,
    },
    {
        key: 'product-pictures/photo-bank/pexels-4.jpg',
        src: PhotoBankPexels4,
    },
    {
        key: 'product-pictures/photo-bank/pexels-14.jpg',
        src: PhotoBankPexels14,
    },
    {
        key: 'product-pictures/photo-bank/pexels-15.jpg',
        src: PhotoBankPexels15,
    },
    {
        key: 'product-pictures/photo-bank/pexels-16.jpg',
        src: PhotoBankPexels16,
    },
    {
        key: 'product-pictures/photo-bank/pexels-17.jpg',
        src: PhotoBankPexels17,
    },
    {
        key: 'product-pictures/photo-bank/pexels-18.jpg',
        src: PhotoBankPexels18,
    },
    {
        key: 'product-pictures/photo-bank/pexels-19.jpg',
        src: PhotoBankPexels19,
    },
    {
        key: 'product-pictures/photo-bank/pexels-20.jpg',
        src: PhotoBankPexels20,
    },
    {
        key: 'product-pictures/photo-bank/1.png',
        src: PhotoBank1,
    },
    {
        key: 'product-pictures/photo-bank/2.png',
        src: PhotoBank2,
    },
    {
        key: 'product-pictures/photo-bank/3.png',
        src: PhotoBank3,
    },
    {
        key: 'product-pictures/photo-bank/4.png',
        src: PhotoBank4,
    },
    {
        key: 'product-pictures/photo-bank/5.png',
        src: PhotoBank5,
    },
    {
        key: 'product-pictures/photo-bank/6.png',
        src: PhotoBank6,
    },
    {
        key: 'product-pictures/photo-bank/7.png',
        src: PhotoBank7,
    },
    {
        key: 'product-pictures/photo-bank/8.png',
        src: PhotoBank8,
    },
    {
        key: 'product-pictures/photo-bank/9.png',
        src: PhotoBank9,
    },
    {
        key: 'product-pictures/photo-bank/10.png',
        src: PhotoBank10,
    },
    {
        key: 'product-pictures/photo-bank/11.png',
        src: PhotoBank11,
    },
    {
        key: 'product-pictures/photo-bank/12.png',
        src: PhotoBank12,
    },
    {
        key: 'product-pictures/photo-bank/13.png',
        src: PhotoBank13,
    },
    {
        key: 'product-pictures/photo-bank/14.png',
        src: PhotoBank14,
    },
    {
        key: 'product-pictures/photo-bank/15.png',
        src: PhotoBank15,
    },
    {
        key: 'product-pictures/photo-bank/16.png',
        src: PhotoBank16,
    },
    {
        key: 'product-pictures/photo-bank/17.png',
        src: PhotoBank17,
    },
    {
        key: 'product-pictures/photo-bank/18.png',
        src: PhotoBank18,
    },
    {
        key: 'product-pictures/photo-bank/19.png',
        src: PhotoBank19,
    },
    {
        key: 'product-pictures/photo-bank/20.png',
        src: PhotoBank20,
    },
    {
        key: 'product-pictures/photo-bank/21.png',
        src: PhotoBank21,
    },
    {
        key: 'product-pictures/photo-bank/birth-1.png',
        src: PhotoBankBirth1,
    },
    {
        key: 'product-pictures/photo-bank/birth-2.png',
        src: PhotoBankBirth2,
    },
    {
        key: 'product-pictures/photo-bank/birth-3.png',
        src: PhotoBankBirth3,
    },
    {
        key: 'product-pictures/photo-bank/birth-4.png',
        src: PhotoBankBirth4,
    },
    {
        key: 'product-pictures/photo-bank/birth-5.png',
        src: PhotoBankBirth5,
    },
    {
        key: 'product-pictures/photo-bank/birth-6.png',
        src: PhotoBankBirth6,
    },
    {
        key: 'product-pictures/photo-bank/birth-7.png',
        src: PhotoBankBirth7,
    },
    {
        key: 'product-pictures/photo-bank/birth-8.png',
        src: PhotoBankBirth8,
    },
    {
        key: 'product-pictures/photo-bank/birth-9.png',
        src: PhotoBankBirth9,
    },
    {
        key: 'product-pictures/photo-bank/birth-10.png',
        src: PhotoBankBirth10,
    },
    {
        key: 'product-pictures/photo-bank/birth-11.png',
        src: PhotoBankBirth11,
    },
    {
        key: 'product-pictures/photo-bank/birth-12.png',
        src: PhotoBankBirth12,
    },
    {
        key: 'product-pictures/photo-bank/birth-13.png',
        src: PhotoBankBirth13,
    },
    {
        key: 'product-pictures/photo-bank/birth-14.png',
        src: PhotoBankBirth14,
    },
    {
        key: 'product-pictures/photo-bank/birth-15.png',
        src: PhotoBankBirth15,
    },
];

export const getPhotoSrcFromKey = (key) => {
    for (let i = 0; i < photoBank.length; i++) {
        if (photoBank[i].key === key) {
            return photoBank[i].src;
        }
    }
};