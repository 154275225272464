import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { Alert, Box, Button, Stack } from "@mui/material";

import Category from "./category";
import Description from "./description";
import Details from "./details";
import Preview from "./preview";
import Schedule from "./schedule";
import ProductType from "./product-type";
import ProductSelector from "./product-selector";
import ResourceSelector from "./resource-selector";
import { API, PRODUCT_TYPES, PaywallTypes } from "../../constants";
import { Modal, ModalTitle, ModalSubTitle } from "./product.style";
import { get, post, patch, destroy } from "../../services/api.services";
import { fetchFolders } from "../../clients/folder";
import { fetchPaymentPlans } from "../../clients/paymentPlan";
import { photoBank } from "../../utils/photo-bank";
import { SET_PRODUCT, SET_PAYWALL } from "../../store/actions";

const DEFAULT_IMG = photoBank[0];

export default function AddProduct({
  productId,
  productType = null,
  onDismiss,
}) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.account.token);
  const user = useSelector((state) => state.account.user);
  const foldersById = useSelector((state) => state.folders.foldersById);
  const folderIds = useSelector((state) => state.folders.folderIds);
  const productsById = useSelector((state) => state.products.productsById);
  const accountDefaultPaymentPlanId = useSelector(
    (state) => state.paymentPlans.defaultPaymentPlanId,
  );

  const product = productsById[productId];

  const [page, setPage] = useState(1);
  const [title, setTitle] = useState(product ? product.title : "");
  const [categories, setCategories] = useState(
    product ? product.categories.split(",") : [],
  );
  const [otherCategory, setOtherCategory] = useState();
  const [photoKey, setPhotoKey] = useState(
    product ? product.image : DEFAULT_IMG.key,
  );
  const [photoPreview, setPhotoPreview] = useState(
    product ? product.image_url : DEFAULT_IMG.src,
  );
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState(
    product && !product.image_url.includes("photo-bank")
      ? product.image_url
      : undefined,
  );
  const [description, setDescription] = useState(
    product ? product.description : "",
  );
  const [price, setPrice] = useState(product ? product.price : 50);
  const [priceDescription, setPriceDescription] = useState(product ? product.price_description || product.price || 50 : '');
  const [bookingTarget, setBookingTarget] = useState(product ? product.booking_target : null);
  const [bookingTargetType, setBookingTargetType] = useState(product ? product.booking_target_type : "email");
  const [durationHours, setDurationHours] = useState(
    product ? Math.floor(product.duration / 60) % 60 : 0,
  );
  const [durationMins, setDurationMins] = useState(
    product ? product.duration % 60 : 30,
  );
  const [maxParticipants, setMaxParticipants] = useState(
    product ? product.max_sales : null,
  );
  const [scheduledAt, setScheduledAt] = useState(
    product ? product.event_date : null,
  );
  const [inPerson, setInPerson] = useState(product ? product.in_person : false);
  const [virtual, setVirtual] = useState(product ? product.virtual : false);
  const [link, setLink] = useState(product ? product.link : null);
  const [location, setLocation] = useState(product ? product.location : null);
  const [generateMeetingLink, setGenerateMeetingLink] = useState(
    product ? product.generate_meeting_link : true,
  );
  const [chosenProductType, setChosenProductType] = useState(
    productType || null,
  );
  const [generalAccess, setGeneralAccess] = useState(
    product
      ? product.general_access.indexOf("public") !== -1
        ? "public"
        : "private"
      : "public",
  );
  const [defaultPaymentPlan, setDefaultPaymentPlan] = useState(
    product ? product.default_payment_plan.id : null,
  );
  const [additionalPaymentPlansOffered, setAdditionalPaymentPlansOffered] =
    useState(
      product
        ? product.additional_payment_plans_offered.map((plan) => plan.id)
        : [],
    );
  const [cancelationPolicy, setCancelationPolicy] = useState(
    product
      ? product.cancelation_policy
      : user.profile.default_cancelation_policy,
  );
  const [sharedWithFolderIdsUnedited, setSharedWithFolderIdsUnedited] =
    useState([]);
  const [sharedWithFolderIds, setSharedWithFolderIds] = useState([]);
  const [insuranceOptions, setInsuranceOptions] = useState(product ? product.insurance_accepted : []);
  const [shareIdsByFolderId, setShareIdsByFolderId] = useState({});
  const [selectedResourceIds, setSelectedResourceIds] = useState(
    product
      ? product.resources
          .sort((a, b) => a.order - b.order)
          .map((pr) => pr.resource.id)
      : [],
  );
  const [selectedProductIds, setSelectedProductIds] = useState(
    product
      ? product.child_products
          .sort((a, b) => a.order - b.order)
          .map((cp) => cp.child_product.id)
      : [],
  );
  const [workflow, setWorkflow] = useState(product ? product.workflow : {});
  const [hasErrors, setHasErrors] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);

  let modalTitle;
  let numPages = 5;

  switch (chosenProductType) {
    case PRODUCT_TYPES.SINGLE:
      modalTitle = `${product ? "Edit" : "New"} 1-on-1 Support / Coaching`;
      //numPages = 5;
      break;
    case PRODUCT_TYPES.GROUP:
      modalTitle = `${product ? "Edit" : "New"} Live Group Learning`;
      //numPages = 6;
      break;
    case PRODUCT_TYPES.SUPPORT:
      modalTitle = `${product ? "Edit" : "New"} Support Group`;
      //numPages = 6;
      break;
    case PRODUCT_TYPES.RESOURCE:
      modalTitle = `${product ? "Edit" : "New"} On-Demand Learning`;
      //numPages = 6;
      break;
    case PRODUCT_TYPES.TECH:
      modalTitle = `${product ? "Edit" : "New"} Tech Solution`;
      //numPages = 6;
      break;
    case PRODUCT_TYPES.PRODUCT:
      modalTitle = `${product ? "Edit" : "New"} Physical Product`;
      //numPages = 6;
      break;
    case PRODUCT_TYPES.PACKAGE:
      modalTitle = `${product ? "Edit" : "New"} Package of Services`;
      //numPages = 6;
      break;
    default:
      modalTitle = "New Service";
      //numPages = 5;
      break;
  }

  const handleBackBtn = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  const handleNextBtn = () => {
    if (page < numPages) {
      setPage((prev) => prev + 1);
    }
  };

  const fetchShares = async () => {
    const result = await get(
      `${API.SHARE}?product_id=${product.id}&share_type=product`,
      token,
    );

    if (result.status === 403 || result.status === 401) {
      navigate("/login/experts");
    } else if (result.status === 200) {
      const folderIds = result.data.map((share) => share.folder.id);
      const generalAccessTags = product
        ? product.general_access.filter(
            (tag) => tag === "link" || tag === "clients",
          )
        : [];

      setSharedWithFolderIds([...folderIds, ...generalAccessTags]);
      setSharedWithFolderIdsUnedited([...folderIds, ...generalAccessTags]);

      const newShareIdsByFolderId = {};

      result.data.forEach((share) => {
        newShareIdsByFolderId[share.folder.id] = share.id;
      });

      setShareIdsByFolderId(newShareIdsByFolderId);
    } else {
      // TODO: Handle errors
    }
  };

  const share = async (productId) => {
    const [sharesToCreate, sharesToDelete] = diffArrays(
      sharedWithFolderIdsUnedited.filter(
        (val) => val !== "clients" && val !== "link",
      ),
      sharedWithFolderIds.filter((val) => val !== "clients" && val !== "link"),
    );

    let hasShareError = false;

    for (let i = 0; i < sharesToCreate.length; i++) {
      const result = await post(
        `${API.SHARE}`,
        {
          folder_id: sharesToCreate[i],
          product_id: productId,
        },
        token,
      );

      if (result.status !== 201) {
        hasShareError = true;
      }
    }

    for (let i = 0; i < sharesToDelete.length; i++) {
      const result = await destroy(
        `${API.SHARE}${shareIdsByFolderId[sharesToDelete[i]]}/`,
        token,
      );

      if (result.status !== 204) {
        hasShareError = true;
      }
    }

    return !hasShareError;
  };

  const validateCanProceed = () => {
    setNextDisabled(false);
    if (page === 1) {
      if (!chosenProductType) {
        setNextDisabled(true);
      }
    } else if (page === 2) {
      if (title === "" || (categories.length === 0 && !otherCategory)) {
        setNextDisabled(true);
      }
    } else if (page === 3) {
      if (/*description === "" || */photoKey === null) {
        setNextDisabled(true);
      }
    }
  };

  useEffect(() => {
    validateCanProceed();
  }, [
    page,
    title,
    categories,
    otherCategory,
    description,
    photoKey,
    chosenProductType,
  ]);

  useEffect(() => {
    if (defaultPaymentPlan === null && accountDefaultPaymentPlanId) {
      setDefaultPaymentPlan(accountDefaultPaymentPlanId);
    }
  }, [accountDefaultPaymentPlanId]);

  const save = async () => {
    setHasErrors(false);
    const cleanCategories = categories.filter((cat) => cat !== "Other");

    const cleanGeneralAccess = [];

    if (generalAccess === "public") {
      cleanGeneralAccess.push("public");
    } else {
      if (sharedWithFolderIds.indexOf("clients") !== -1) {
        cleanGeneralAccess.push("clients");
      }

      if (sharedWithFolderIds.indexOf("link") !== -1) {
        cleanGeneralAccess.push("link");
      }

      cleanGeneralAccess.push("private");
    }

    const data = {
      title,
      price,
      price_description: priceDescription,
      categories: otherCategory
        ? [...cleanCategories, otherCategory].join(",")
        : cleanCategories.join(","),
      product_type: chosenProductType,
      image: photoKey,
      description,
      event_date: scheduledAt,
      max_sales: maxParticipants,
      duration: parseInt(durationHours) * 60 + parseInt(durationMins),
      in_person: inPerson,
      virtual,
      link,
      location,
      general_access: cleanGeneralAccess,
      insurance_accepted: insuranceOptions,
      resource_ids: selectedResourceIds,
      child_product_ids: selectedProductIds,
      default_payment_plan_id: defaultPaymentPlan,
      additional_payment_plans_offered_ids: additionalPaymentPlansOffered,
      cancelation_policy: cancelationPolicy,
      generate_meeting_link: generateMeetingLink,
      workflow,
      booking_target: bookingTarget,
      booking_target_type: bookingTargetType,
    };

    if (product) {
      const result = await patch(`${API.PRODUCT}${product.id}/`, data, token);

      if (result.status === 403 || result.status === 401) {
        navigate("/login/experts");
      } else if (result.status === 200) {
        const successfulShare = await share(result.data.id);

        if (successfulShare) {
          onDismiss(result.data, true);
        } else {
          setHasErrors(true);
        }
      } else {
        setHasErrors(true);
      }
    } else {
      const result = await post(`${API.PRODUCT}`, data, token);

      if (result.status === 403 || result.status === 401) {
        navigate("/login/experts");
      } else if (result.status === 201) {
        const successfulShare = await share(result.data.id);

        if (successfulShare) {
          onDismiss(result.data, false);
        } else {
          setHasErrors(true);
        }
      } else {
        setHasErrors(true);
      }
    }
  };

  const diffArrays = (array1, array2) => {
    const diff1 = array2.filter((x) => !array1.includes(x));
    const diff2 = array1.filter((x) => !array2.includes(x));
    return [diff1, diff2];
  };

  useEffect(() => {
    fetchPaymentPlans(dispatcher, navigate, token);
  }, []);

  useEffect(() => {
    if (product) {
      fetchShares();
    }
    fetchFolders(dispatcher, navigate, token);
    fetchPaymentPlans(dispatcher, navigate, token);
  }, []);

  const getPageTitle = () => {
    switch (page) {
      case 1:
        return "Service Type";
      case 2:
        return "Title & Category";
      case 3:
        return "Photo & Description";
        /*if (chosenProductType === PRODUCT_TYPES.RESOURCE) {
          return "Description";
        } else {
          return "Photo & Description";
        }*/
      case 4: {
        return "More Details";
        /*if (chosenProductType === PRODUCT_TYPES.SINGLE) {
          return "More Details";
        } else if (chosenProductType === PRODUCT_TYPES.GROUP) {
          return "Date & Time";
        } else if (chosenProductType === PRODUCT_TYPES.PACKAGE) {
          return "Services Included";
        } else if (chosenProductType === PRODUCT_TYPES.RESOURCE) {
          return "Resources Included";
        }*/
      }
      case 5: {
        return "Preview & Submit";
        /*if (chosenProductType === PRODUCT_TYPES.SINGLE) {
          return "Preview & Submit";
        } else {
          return "More Details";
        }*/
      }
      case 6:
        return "Preview & Submit";
      default:
        return null;
    }
  };

  const getPageContent = () => {
    const ProductTypeComponent = (
      <ProductType
        chosenProductType={chosenProductType}
        setChosenProductType={setChosenProductType}
      />
    );

    const CategoryComponent = (
      <Category
        title={title}
        setTitle={setTitle}
        categories={categories}
        setCategories={setCategories}
        otherCategory={otherCategory}
        setOtherCategory={setOtherCategory}
      />
    );

    const DescriptionComponent = (
      <Description
        description={description}
        setDescription={setDescription}
        photoKey={photoKey}
        setPhotoKey={setPhotoKey}
        photoPreview={photoPreview}
        setPhotoPreview={setPhotoPreview}
        uploadedPhotoUrl={uploadedPhotoUrl}
        productType={chosenProductType}
      />
    );

    const ScheduleComponent = (
      <Schedule
        scheduledAt={scheduledAt}
        setScheduledAt={setScheduledAt}
        showTimezone={true}
      />
    );

    const DetailsComponent = (
      <Details
        price={price}
        setPrice={setPrice}
        priceDescription={priceDescription}
        setPriceDescription={setPriceDescription}
        bookingTarget={bookingTarget}
        setBookingTarget={setBookingTarget}
        bookingTargetType={bookingTargetType}
        setBookingTargetType={setBookingTargetType}
        durationHours={durationHours}
        setDurationHours={setDurationHours}
        durationMins={durationMins}
        setDurationMins={setDurationMins}
        maxParticipants={maxParticipants}
        setMaxParticipants={setMaxParticipants}
        inPerson={inPerson}
        setInPerson={setInPerson}
        virtual={virtual}
        setVirtual={setVirtual}
        productType={chosenProductType}
        generalAccess={generalAccess}
        setGeneralAccess={setGeneralAccess}
        sharedWithFolderIds={sharedWithFolderIds}
        setSharedWithFolderIds={setSharedWithFolderIds}
        folders={folderIds.map((id) => foldersById[id])}
        link={link}
        setLink={setLink}
        location={location}
        setLocation={setLocation}
        defaultPaymentPlan={defaultPaymentPlan}
        setDefaultPaymentPlan={setDefaultPaymentPlan}
        cancelationPolicy={cancelationPolicy}
        setCancelationPolicy={setCancelationPolicy}
        additionalPaymentPlansOffered={additionalPaymentPlansOffered}
        setAdditionalPaymentPlansOffered={setAdditionalPaymentPlansOffered}
        generateMeetingLink={generateMeetingLink}
        setGenerateMeetingLink={setGenerateMeetingLink}
        insuranceOptions={insuranceOptions}
        setInsuranceOptions={setInsuranceOptions}
      />
    );

    const PreviewComponent = (
      <Preview
        title={title}
        categories={categories}
        description={description}
        price={price}
        durationHours={durationHours}
        durationMins={durationMins}
        photoPreview={photoPreview}
        virtual={virtual}
        inPerson={inPerson}
        maxParticipants={maxParticipants}
        link={link}
        location={location}
        productType={chosenProductType}
        insuranceOptions={insuranceOptions}
        selectedResourceIds={selectedResourceIds}
        selectedProductIds={selectedProductIds}
        additionalPaymentPlansOffered={additionalPaymentPlansOffered}
        cancelationPolicy={cancelationPolicy}
        defaultPaymentPlan={defaultPaymentPlan}
        priceDescription={priceDescription}
      />
    );

    const ResourceSelectorComponent = (
      <ResourceSelector
        selectedResourceIds={selectedResourceIds}
        setSelectedResourceIds={setSelectedResourceIds}
      />
    );

    const ProductSelectorComponent = (
      <ProductSelector
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        workflow={workflow}
        setWorkflow={setWorkflow}
      />
    );

    switch (page) {
      case 1:
        return ProductTypeComponent;
      case 2:
        return CategoryComponent;
      case 3:
        return DescriptionComponent;
      case 4: {
        return DetailsComponent;
        /*if (chosenProductType === PRODUCT_TYPES.SINGLE) {
          return DetailsComponent;
        } else if (chosenProductType === PRODUCT_TYPES.GROUP) {
          return ScheduleComponent;
        } else if (chosenProductType === PRODUCT_TYPES.PACKAGE) {
          return ProductSelectorComponent;
        } else if (chosenProductType === PRODUCT_TYPES.RESOURCE) {
          return ResourceSelectorComponent;
        }*/
      }
      case 5: {
        return PreviewComponent;
        /*if (chosenProductType === PRODUCT_TYPES.SINGLE) {
          return PreviewComponent;
        } else {
          return DetailsComponent;
        }*/
      }
      case 6:
        return PreviewComponent;
      default:
        return null;
    }
  };

  return (
    <Modal>
      <Stack spacing={2}>
        <ModalTitle>{modalTitle}</ModalTitle>
        <ModalSubTitle>
          {getPageTitle()} ({page} / {numPages})
        </ModalSubTitle>
        {hasErrors && <Alert color="error">An error occured.</Alert>}
        {getPageContent()}
        <Stack direction="row" justifyContent="space-around">
          {page > 1 && (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleBackBtn}
            >
              Back
            </Button>
          )}
          {page < numPages - 1 && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleNextBtn}
              disabled={nextDisabled}
            >
              Next
            </Button>
          )}
          {page === numPages - 1 && (
            <Button color="primary" variant="contained" onClick={handleNextBtn}>
              Preview
            </Button>
          )}
          {page === numPages && (
            <Button color="primary" variant="contained" onClick={save}>
              Submit
            </Button>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
}
