/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Modal = styled.div`
  background: #ffffff;
  padding: 20px;
  width: 600px;
  max-width: 90%;
  border-radius: 6px;
  margin: 70px auto;
  max-height: calc(100% - 140px);
  overflow-y: scroll;
`;

export const ModalTitle = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #210b2b;
`;

export const ModalSubTitle = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #210b2b;
`;

export const ModalFormItem = styled.div`
  font-family: "Nunito";
  color: #210b2b;
  width: 500px;
  max-width: 90%;
  margin-bottom: 20px;
`;

export const ModalFormItemLabel = styled.div`
  font-family: "Nunito";
  color: #210b2b;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const PhotoDrop = styled.div`
  width: 300px;
  height: 200px;
  background: #fff4f4;
  border: 1px dashed #5371ff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  color: #5371ff;
  margin: 0 auto;
  cursor: pointer;
`;

export const ProductContainer = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #210b2b;
  width: 100%;
  justify-content: center;
`;

export const ProductLabel = styled.div`
  font-weight: 500;
  font-size: 13px;
  opacity: 0.5;
  text-transform: uppercase;
`;

export const ProductCategories = styled.div`
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 8px;
`;

export const ProductValue = styled.div`
  font-weight: 500;
  font-size: 15px;
  text-align: right;
`;

export const ProductTitle = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #210b2b;
`;

export const CardTitle = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #210b2b;
  overflow: hidden !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export const CardDescription = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #210b2b;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const CardPrice = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: right;
`;

export const CardDate = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  cont-weight: 400;
  font-size: 12px;
  margin-bottom: 2px;
  color: #535353;
`;

export const SuccessModal = styled.div`
  width: 275px;
  background: #ffffff;
  border-radius: 10px;
  margin: 5% auto;
  padding: 40px;
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #210b2b;
  &:active {
    border: none;
  }
`;

export const PhotoBankImg = styled.img`
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 3px;
`;

export const PhotoBankImgActive = styled.img`
  width: 100%;
  border-radius: 4px;
  border: 3px solid #5371ff;
`;

export const ScheduleTitle = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: #210b2b;
`;

export const Range = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #210b2b;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  width: 200px;
  cursor: pointer;
  padding: 5px;
  margin: 6px;
`;

export const Disclaimer = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

export const ReimbursementDetailLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
