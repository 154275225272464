import React from 'react';

import { Box, CircularProgress, IconButton } from '@mui/material';

import {
    DrawerTitle,
} from '../../session/Session.style';
import DismissIcon from '../../../assets/icons/Dismiss.svg';

function Drawer({ title, loading, onDismiss, children }) {
    return (
        <Box sx={{ width: 350, maxWidth: '100%', background: '#F8F8F8', p: 3, height: '100%', overflowY: 'scroll' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3,
            }}>
                <DrawerTitle>
                    {title}
                </DrawerTitle>
                {onDismiss && (
                    <IconButton onClick={onDismiss}>
                        <img src={DismissIcon} alt="close" width={12} height={12} />
                    </IconButton>
                )}
            </Box>
            {loading && (
                <Box sx={{ mt: 4, textAlign: 'center', height: '100%' }}>
                    <CircularProgress style={{ color: '#5371ff' }} />
                </Box>
            )}
            <Box>
                {children}
            </Box>
        </Box>
    );
}

export default Drawer;
